import * as React from 'react';
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

const BootstrapInput = styled(TextField)(({ theme }) => ({
  '& .Mui-error fieldset': {
    borderColor: 'red !important',
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#2b2b2b',
    padding: '.08rem .75rem',
    width: '100%',
    '&.Mui-focused fieldset': {
      boxShadow: '0 0 0 0.2rem rgba(0,0,0,.29)',
      borderColor: 'rgba(0,0,0,.29)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0,0,0,.29)',
    },
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    border: 'none !important',
    width: '',
    padding: '.375rem 0rem',
    lineHeight: 1.5,
    fontZize: '1rem',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  '& .MuiInputBase-input:focus': {
    border: 'none !important',
    boxShadow: 'unset',
  },
}));

const TextfieldNoborder = (props) => {
  return <BootstrapInput {...props} />;
};

export default TextfieldNoborder;
