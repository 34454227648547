import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { settings } from './Settings';
import moment from 'moment';
import { Grid } from '@mui/material';

const apiURL = settings.baseUrl;
const imageUriPath = settings.imageUriPath;
const db = settings.MongodbAppName;

const collection = 'batch';
const file_url =
  'https://firebasestorage.googleapis.com/v0/b/soulters-api-db.appspot.com/o/';
var ts = [];
var bs = [];
function batchForm() {
  const navigate = useNavigate();
  const { course, date } = useParams();
  let { state } = useLocation();
  const [batch, setBatch] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state?.batches) {
      setBatch(state.batches);
    }
  }, [state]);

  return (
    <div className="container-fluid">
      <Header />
      <div className="container px-2 mb-16">
        <Link
          to="/"
          className="flex justify-start items-center mb-5 text-[#2271B1]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5 mx-2 mr-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
            />
          </svg>
          <h4 className="text-[20px] font-bold mr-4"> {date} </h4>
        </Link>
        <div className="border-b border-[#BAD3E7] py-5">
          <h4 className="text-[20px] font-bold mr-4 mb-4 text-blue-400 mx-4">
            {' '}
            {course}{' '}
          </h4>
          {/* <h1 className="font-bold text-xl my-2">Understand Qur’an Course – 1 [QC1]</h1> */}
          <p className="mx-4">{batch[0]?.course?.description}</p>
        </div>
        <h1 className="font-bold text-xl my-5 mt-0 border-b border-[#BAD3E7] py-5 mx-4">
          Batch List{' '}
        </h1>
        <div className="flex flex-row w-full flex-wrap justify-start items-start  py-5">
          <Grid container spacing={3}>
            {Array.isArray(batch) &&
              batch.map((batch, bIndex) => (
                <Grid item lg={4} sm={6} xs={12}>
                  <Link
                    to={`/batch-list/${batch.course?.title}/${batch.date}/${batch._id}`}
                    key={bIndex}
                    className="w-full"
                  >
                    <div className="flex gap-4">
                      <img
                        src={`${imageUriPath}${batch.imagePath}`}
                        style={{
                          height: '200px',
                          width: '150px',
                        }}
                      />
                      <div className="flex-1 w-full flex flex-col border-gray-500 pb-3 mb-3">
                        <div className="flex mt-2 justify-start items-start pr-2 flex-col">
                          <div className="flex justify-start mx-2 text-lg font-bold">
                            {batch?.teacher?.name}
                          </div>
                          <div className="flex gap-2">
                            {batch.batch_no && (
                              <p className="bgd flex justify-center items-center bg-blue-800 text-white rounded-full p-2 text-xs py-1">
                                BATCH NO {batch.batch_no}
                              </p>
                            )}
                            {batch.date &&
                              moment(batch.date).isAfter() &&
                              batch.registrationLink && (
                                <a
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open(
                                      batch.registrationLink,
                                      '_blank'
                                    );
                                  }}
                                  href={batch.registrationLink}
                                  target="_blank"
                                  className="bgd flex justify-center items-center bg-green-500 text-white rounded-full p-2 text-xs py-1"
                                >
                                  Enroll now
                                </a>
                              )}
                          </div>
                        </div>
                        <div className="flex mt-2 justify-start items-center pr-2">
                          <div className="flex justify-start mx-2 text-sm">
                            {batch.language}
                          </div>
                          <div className="dots"></div>
                          <p className="xxs flex justify-center items-center rounded-md p-2 text-sm py-1">
                            {batch.location}
                          </p>
                        </div>
                        <div className="flex mt-2 justify-start items-center pr-2">
                          <div className="flex justify-start items-center mx-2 text-sm ">
                            {batch.time} {batch.timeAt}{' '}
                            <div className="dots mx-2"></div> {batch.hours} Hour{' '}
                            {batch.minutes} Minutes
                          </div>
                        </div>
                        <div className="mx-2">
                          {batch.date && moment(batch.date).isAfter() ? (
                            <div className="flex justify-start items-center text-sm  mt-2">
                              Start On
                              <div className="dots mx-2"></div>{' '}
                              {moment(batch.date).format('Do MMM YYYY')}
                            </div>
                          ) : (
                            batch.date && (
                              <div className="flex justify-start items-center text-sm  mt-2">
                                Started On
                                <div className="dots mx-2"></div>{' '}
                                {moment(batch.date).format('Do MMM YYYY')}
                              </div>
                            )
                          )}
                        </div>
                        {batch.date &&
                          moment(batch.date).isAfter() &&
                          batch.registrationLink && (
                            <div className="mx-2">
                              <div className="animate-updown text-green-700 font-bold text-start mt-4">
                                Upcoming Course
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </Link>
                </Grid>
              ))}
          </Grid>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default batchForm;
