import { useState } from 'react';
import logo from '../assets/logo.png'
import bgs from '../assets/bgs.jpeg'
import './Header.css'
import { useParams, Link,useNavigate,useLocation } from 'react-router-dom';
export const Footer =  ({ title, description, route }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const hd = false
  const w = false
  return (
   <div className='flex fixed bottom-0 w-full py-6 flex-row justify-between px-8 bg-black'>
        <p className='text-white'>Copyright © 2024 Alfalah Manzil Trust | Powered by Alfalah Manzil Trust.</p>
        <p className='text-white'>Privacy Policy | Terms & Conditions</p>
   </div>
  );
}


