/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { RouterApp } from "./router"; // Assuming RouterApp is correctly defined and exported
import { Header } from "./components/Header"; // Assuming Header is correctly defined and exported

function App() {
  const location = useLocation();
  const [login, setLogin] = useState(true);

  useEffect(() => {
    setLogin(location.pathname !== '/login');
  }, [location.pathname]);

  return (
    <div id="root">
      <div>
        <div>
          <div className="w-full">
              <RouterApp />
          </div>
        </div>
      </div>
      {/* Assuming RouterApp is correctly defined and renders routes */}
    </div>
  );
}

export default App;
