import axios from 'axios';
import { settings } from './Settings';
import { Header } from './components/Header';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams, Link } from 'react-router-dom';

const apiURL = settings.baseUrl;
const db = settings.MongodbAppName;

function CalendarComponent() {
  const { course } = useParams();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({
    type: '',
    teacher: '',
    language: '',
    date: '',
    time: '',
    batch_no: '',
    course: '',
  });
  const [batch, setBatch] = useState([]);
  const [courses, setCourses] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    if (course) {
      setSearch((prevSearch) => ({
        ...prevSearch,
        course,
      }));
      getBatch(course);
    } else {
      getBatch();
    }
    init();
  }, [course]);

  const init = () => {
    getCourses();

    getLanguages();
    getTeachers();
  };

  const applySearch = () => {
    getBatch();
  };

  const resetSearch = () => {
    setSearch({
      type: '',
      teacher: '',
      language: '',
      date: '',
      time: '',
      batch_no: '',
      course: '',
    });
    setShowFilter(false);
    getBatch();
  };

  const dayReturn = (d) => {
    return moment(d).format('DD');
  };

  const restReturn = (d) => {
    return moment(d).format('MMM YYYY,ddd');
  };

  const getCourses = async () => {
    setLoading(true);

    const collection = 'courses';
    const filter = { status: 'true' };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };
  const getTeachers = async () => {
    setLoading(true);

    const collection = 'teachers';
    const filter = { status: 'true' };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setTeachers(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getLanguages = async () => {
    setLoading(true);

    const collection = 'languages';
    const filter = { status: 'true' };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setLanguages(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getCenters = async () => {
    setLoading(true);

    const collection = 'centers';
    const filter = { status: 'true' };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setCenters(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getBatch = async (c) => {
    setLoading(true);

    const collection = 'batch';
    const filter = { approval_status: 'approved' };
    if (search.teacher) filter.teacher = search.teacher;
    if (search.language) filter.language = search.language;
    if (search.date) filter.date = search.date;
    if (search.time) filter.time = search.time;
    if (search.batch_no) filter.batch_no = search.batch_no;
    if (search.course) filter.course = search.course;
    if (c) filter.course = c;
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setBatch(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };
  // Handle search criteria change
  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  // Handle apply search button click

  // Handle reset search button click

  // Helper function to return day

  // Helper function to return rest

  // JSX markup for rendering the component
  return (
    <div>
      <Header
        title="COURSE SEARCH"
        description="You can check course schedules according to the calendar dates and timings."
      />
      <div>
        {/* Search and Filter UI */}
        <div className="container">
          <div className="flex justify-start items-start px-4 sm:px-auto sm:justify-start flex-col sm:flex-row sm:items-center mb-10 container">
            {/* Search */}
            <div className="flex mb-2 sm:mb-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 mx-2 mr-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                ></path>
              </svg>
              <h4 className="text-xl font-bold mr-4">Search </h4>
            </div>

            {/* Search input and buttons */}
            <div className="flex justify-start items-center border border-gray-300 rounded-full p-2 py-1 w-full">
              {/* Search icon SVG */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                ></path>
              </svg>

              {/* Search input */}
              <input
                type="text"
                placeholder="Search Course"
                value={search.course}
                onChange={(e) =>
                  setSearch({ ...search, course: e.target.value })
                }
                className="no-border w-full text-sm rounded-full"
              />

              {/* Clear search button */}
              {search.course && (
                <span
                  onClick={() => setSearch({ ...search, course: '' })}
                  className="w-10 text-center cursor-pointer px-2"
                >
                  X
                </span>
              )}

              {/* Apply search button */}
              <span
                onClick={applySearch}
                className="text-center cursor-pointer px-2"
              >
                Search
              </span>
            </div>

            {/* Filter button */}
            <button
              onClick={() => setShowFilter(true)}
              className="sm:mx-2 p-2 bg-white border rounded-full px-6 flex w-full sm:w-auto my-4 sm:my-auto"
            >
              {/* Filter icon SVG */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 mx-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                ></path>
              </svg>
              Filter
            </button>
          </div>

          {/* Filter options */}
          {showFilter && (
            <div>
              <div className="px-5 py-2 mt-1 flex flex-col gap-3.5">
                <div className="grid grid-cols-6 gap-3.5">
                  <div className="sm:col-span-2 col-span-6 mt-2">
                    <label className="inline-block mb-2">Teacher</label>
                    <select
                      name="teacher"
                      value={search.teacher}
                      onChange={handleSearchChange}
                      className="bg-[length:20px_auto] disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 bg-chevron-black transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50  sm:mr-2"
                      aria-label="Select Teacher"
                    >
                      <option value="">All Teachers</option>
                      {teachers.map((teacher) => (
                        <option key={teacher.id} value={teacher.name}>
                          {teacher.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="sm:col-span-2 col-span-6 mt-2">
                    <label className="inline-block mb-2">Batch Number</label>
                    <input
                      name="batch_no"
                      value={search.batch_no}
                      onChange={handleSearchChange}
                      type="text"
                      placeholder="Batch Number"
                      className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80"
                    />
                  </div>
                  <div className="sm:col-span-2 col-span-6 mt-2">
                    <label className="inline-block mb-2">Language</label>
                    <select
                      name="language"
                      value={search.language}
                      onChange={handleSearchChange}
                      className="bg-[length:20px_auto] disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 bg-chevron-black transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50  sm:mr-2"
                      aria-label="Select Language"
                    >
                      <option value="">All Languages</option>
                      {languages.map((language) => (
                        <option key={language.id} value={language.title}>
                          {language.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mt-4 sm:col-span-3 col-span-6">
                    <label className="inline-block mb-2">Start Date</label>
                    <input
                      name="date"
                      value={search.date}
                      onChange={handleSearchChange}
                      type="date"
                      placeholder="Start Date"
                      className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80"
                    />
                  </div>
                  <div className="mt-4 sm:col-span-3 col-span-6">
                    <label className="inline-block mb-2">Time</label>
                    <input
                      name="time"
                      value={search.time}
                      onChange={handleSearchChange}
                      type="time"
                      placeholder="Time"
                      className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80"
                    />
                  </div>
                </div>
                <div className="px-5 py-2 mt-4 flex gap-3.5 w-full flex-row items-end justify-end mb-10">
                  {/* Apply Search button */}
                  <button
                    onClick={applySearch}
                    className="transition duration-200 border shadow-sm items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-[#3b5998] border-[#3b5998] text-white dark:border-[#3b5998] w-32"
                  >
                    Search
                  </button>
                  {/* Reset and Close button */}
                  <button
                    onClick={resetSearch}
                    className="transition duration-200 border shadow-sm items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-white border-[#3b5998] text-[#3b5998] dark:border-[#3b5998] w-32"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>{/* Your search and filter UI here */}</div>

        {/* Batch Display */}
        <div>
          {batch &&
            batch.map((b) => (
              <Link
                to={`/batch-list/${b.course?.title}/${b.date}/${b._id}`}
                key={b.id}
                style={{ border: '1px solid' }}
                className="flex container flex-col justify-start items-start border-b border-[#BAD3E7] py-5 flex-wrap"
              >
                <div className="flex justify-start items-center w-full flex-wrap px-4 sm:px-auto sm:flex-auto">
                  {/* Day */}
                  <div className="font-bold flex justify-start items-center text-lg mx-2">
                    {dayReturn(b.date)}
                  </div>

                  {/* Date */}
                  <div className="text-sm mx-2"> {restReturn(b.date)}</div>

                  {/* Time */}
                  <div className="font-bold flex justify-start items-center text-[40px] mx-2 translate-y-[-10px]">
                    .
                  </div>
                  <div className="text-sm mx-2"> {b.time}</div>

                  {/* Duration */}
                  <div className="font-bold flex justify-start items-center text-[40px] mx-2 translate-y-[-10px]">
                    .
                  </div>
                  <div className="text-sm mx-2">
                    {b.hours} hours, {b.minutes} minutes
                  </div>

                  {/* Course and Batch Number */}
                  <div className="font-bold flex justify-start items-center text-[40px] mx-2 translate-y-[-10px]">
                    .
                  </div>
                  <div className="text-sm mx-2">
                    {b.course.title}- Batch # {b.batch_no}
                  </div>

                  {/* Language */}
                  <div className="font-bold flex justify-start items-center text-[40px] mx-2 translate-y-[-10px]">
                    .
                  </div>
                  <div className="text-sm mx-2">{b.language}</div>

                  {/* Teacher */}
                  <div className="font-bold flex justify-start items-center text-[40px] mx-2 translate-y-[-10px]">
                    .
                  </div>
                  <div className="text-sm mx-2">{b.teacher.name}</div>
                </div>
              </Link>
            ))}
          {!loading && (!batch || !batch.length) && (
            <p className="flex text-center justify-center items-center font-bold">
              No record found!
            </p>
          )}
          {loading && (
            <p className="flex text-center justify-center items-center font-bold">
              Loading...
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default CalendarComponent;
