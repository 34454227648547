import { useState } from 'react';
import logo from '../assets/logo.png';
import bgs from '../assets/bgs.jpeg';
import './Header.css';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
export const Header = ({ title, description, route }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const hd = false;
  const w = false;
  return (
    <div
      style={{ backgroundImage: `url(${bgs})`, backgroundSize: 'cover' }}
      className={`w-full h-[400px] mainBG mb-10 sm:mb-20 ${hd ? 'ds' : ''} ${
        w ? 'mw' : ''
      }`}
    >
      <div className="bg-[#000000a1] absolute w-full h-[400px]"></div>
      <div className="header container py-10 position relative z-1">
        <div className="flex justify-between sm:px-10 items-center w-full px-3 hidden sm:flex">
          <Link to="/">
            <img src={logo} className="w-20 cursor-pointer" />
          </Link>
          <div className="flex gap-2 justify-center items-center">
            <Link
              to="/"
              className={`mx-2 text-white hidden sm:flex sm:px-4 py-2 ${
                location.pathname == '/' ? 'border-b' : ''
              }`}
            >
              Home
            </Link>
            <Link
              to="/upcoming-courses"
              className={`mx-2 text-white hidden sm:flex sm:px-4 py-2 
                relative top-0 w-fit h-auto py-4 justify-center flex bg-gradient-to-r items-center from-blue-500 via-teal-500 to-pink-500 bg-clip-text text-xl font-extrabold text-transparent text-center select-auto
                ${location.pathname == '/upcoming-courses' ? 'border-b' : ''}`}
            >
              Upcoming Courses
            </Link>
            <Link
              to="/search"
              className={`mx-2 text-white hidden sm:flex sm:px-4 py-2 ${
                location.pathname === '/search' ? 'border-b' : ''
              }`}
            >
              Advance Search
            </Link>
            <Link
              to="/add-batch/new"
              className="mx-2 px-4 w-[130px] items-center flex justify-center text-sm text-white bg-[#2271B1] sm:px-2 py-2 rounded-full sm:px-4"
            >
              Book Course
            </Link>
          </div>
        </div>
        <div className="flex justify-between sm:px-10 items-center w-full px-3 sm:hidden">
          {!showMenu ? (
            <svg
              onClick={() => setShowMenu(true)}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          ) : (
            <svg
              onClick={() => setShowMenu(false)}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          )}
          <Link to="/">
            <img src="@/assets/logo.png" className="w-10 cursor-pointer" />
          </Link>
        </div>
        {showMenu && (
          <div className="flex gap-2 justify-start items-start rounded-md z-20 border shadow-md flex-col bg-white p-10 py-5 fixed left-10 top-20 sm:hidden">
            <Link
              to="/"
              className={`mx-2 sm:px-4 py-2 ${
                location.pathname === '/' ? 'border-b' : ''
              }`}
            >
              Calendar
            </Link>
            <Link
              to="/search"
              className={`mx-2 sm:px-4 py-2 ${
                location.pathname === '/search' ? 'border-b' : ''
              }`}
            >
              Search
            </Link>
            <Link
              to="/add-batch-client/new/edit"
              className={`mx-2 sm:px-4 py-2 ${
                location.pathname === '/search' ? 'border-b' : ''
              }`}
            >
              Book Course
            </Link>
          </div>
        )}
        <div className="flex justify-center items-center flex-col my-16">
          <h1 className="text-white text-[20px] sm:text-[40px] font-bold py-2 uppercase mb-5">
            {title}
          </h1>
          <p className="text-white text-[12px] sm:text-[20px] py-2 text-center">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};
