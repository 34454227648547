import axios from 'axios';
import { settings } from './Settings';
import { Header } from './components/Header';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams, Link } from 'react-router-dom';
import HomeComponentNew from './HomeComponentNew';

const apiURL = settings.baseUrl;
const db = settings.MongodbAppName;
const weeklyDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
function CalendarComponent() {
  const [calendarDates, setCalendarDates] = useState([]);
  const [calendarDatesNew, setCalendarDatesNew] = useState([]);
  const [formattedCalendarDates, setFormattedCalendarDates] = useState([]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [cont, setCont] = useState(false);
  const [currentDay, setCurrentDay] = useState({
    day: moment().format('DD'),
    week: moment().format('ddd'),
    dated: moment().format('MMMM Do YYYY'),
  });
  const [todayIs, setTodayIs] = useState(moment().format('DD-MM-YYYY')); // Store current date

  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const [hideSide, setHideSide] = useState(false);
  const [currentWeek, setCurrentWeek] = useState([]);
  const [weekStart, setWeekStart] = useState(() => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - dayOfWeek);
    startOfWeek.setHours(0, 0, 0, 0); // Set to start of the day
    return startOfWeek;
  });

  const [bgColor, setBgColor] = useState('red');
  const [textColor, setTextColor] = useState('white');
  const [viewMode, setViewMode] = useState('month');
  const [popShow, setPopShow] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [selectedID, setSelectedID] = useState(0);

  const [batch, setBatch] = useState([]);
  const [fetchedBatches, setFetchedBatches] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [centers, setCenters] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  const generateSchedule = (data) => {
    let schedule = [];
    const startDate = moment(data.date);
    let currentDate = moment(startDate);

    if (`${data.end_date_type}` === '2' && data.after_occurance) {
      let recurrenceCount = parseInt(data.after_occurance, 10);
      while (recurrenceCount > 0) {
        const event = {
          date: currentDate.format('YYYY-MM-DD'),
          time: data.time,
          hours: data.hours,
          minutes: data.minutes,
        };
        if (data.repeat !== 'Weekly') {
          schedule.push(event);
        }

        switch (data.repeat) {
          case 'Daily':
            currentDate.add(parseInt(data.repeat_after, 10), 'days');
            break;
          case 'Weekly':
            if (Array.isArray(data.repeat_on)) {
              const currentDay = currentDate.format('dd');
              const currentDayIndex = weeklyDays.indexOf(currentDay);

              data.repeat_on.forEach((day, index) => {
                const _currentDate = moment(currentDate);

                const weekDayIndex = weeklyDays.indexOf(day);
                if (currentDayIndex <= weekDayIndex) {
                  _currentDate.add(weekDayIndex - currentDayIndex, 'days');
                } else {
                  _currentDate.add(weekDayIndex - currentDayIndex + 7, 'days');
                }
                const event = {
                  date: _currentDate.format('YYYY-MM-DD'),
                  time: data.time,
                  hours: data.hours,
                  minutes: data.minutes,
                };

                schedule.push(event);
              });
            } else schedule.push(event);
            currentDate.add(parseInt(data.repeat_after, 10), 'weeks');
            break;
          case 'Monthly':
            currentDate.add(parseInt(data.repeat_after, 10), 'months');
            break;
          default:
            console.warn(`Unsupported repeat interval: ${data.repeat}`);
            return schedule; // Exit early if the repeat interval is unsupported
        }
        recurrenceCount--;

        // Safety check to avoid infinite loop: if currentDate does not change, break the loop
        if (currentDate.isSameOrBefore(moment(event.date))) {
          console.error(
            `Loop detected with currentDate: ${currentDate.format(
              'YYYY-MM-DD'
            )}`
          );
          break;
        }
      }
    } else {
      const endDate = moment(data.by_date);
      while (currentDate.isSameOrBefore(endDate)) {
        const event = {
          date: currentDate.format('YYYY-MM-DD'),
          time: data.time,
          hours: data.hours,
          minutes: data.minutes,
        };
        if (data.repeat !== 'Weekly') {
          schedule.push(event);
        }

        switch (data.repeat) {
          case 'Daily':
            currentDate.add(parseInt(data.repeat_after, 10), 'days');
            break;
          case 'Weekly':
            if (Array.isArray(data.repeat_on)) {
              const currentDay = moment(currentDate).format('dd');
              const currentDayIndex = weeklyDays.indexOf(currentDay);

              data.repeat_on.forEach((day, index) => {
                const _currentDate = moment(currentDate);

                const weekDayIndex = weeklyDays.indexOf(day);
                if (currentDayIndex <= weekDayIndex) {
                  _currentDate.add(weekDayIndex - currentDayIndex, 'days');
                } else {
                  _currentDate.add(weekDayIndex - currentDayIndex + 7, 'days');
                }
                const _event = {
                  date: _currentDate.format('YYYY-MM-DD'),
                  time: data.time,
                  hours: data.hours,
                  minutes: data.minutes,
                };

                schedule.push(_event);
              });
            } else {
              schedule.push(event);
            }
            currentDate.add(parseInt(data.repeat_after, 10), 'weeks');
            break;
          case 'Monthly':
            currentDate.add(parseInt(data.repeat_after, 10), 'months');
            break;
          default:
            console.warn(`Unsupported repeat interval: ${data.repeat}`);
            return schedule; // Exit early if the repeat interval is unsupported
        }

        // Safety check to avoid infinite loop: if currentDate does not change, break the loop
        if (currentDate.isSameOrBefore(moment(event.date))) {
          console.error(
            `Loop detected with currentDate: ${currentDate.format(
              'YYYY-MM-DD'
            )}`
          );
          break;
        }
      }
    }
    return schedule;
  };

  const organizeBatches = (arr) => {
    const organized = {};

    // Iterate through each object in the array
    arr.forEach((obj) => {
      const {
        date,
        course: { title },
      } = obj;

      // If the date doesn't exist in the organized object, create an empty array for it
      if (!organized[date]) {
        organized[date] = {};
      }

      // If the course doesn't exist in the organized object for this date, create an empty array for it
      if (!organized[date][title]) {
        organized[date][title] = [];
      }

      // Push the object to the appropriate batch array
      organized[date][title].push(obj);
    });

    // Convert the organized object into an array of objects
    const result = [];
    for (const date in organized) {
      const batches = [];
      for (const course in organized[date]) {
        batches.push({
          course: course,
          batches: organized[date][course],
        });
      }
      result.push({
        date: date,
        batches: batches,
      });
    }

    return result;
  };
  useEffect(() => {
    processBatches(batch);
  }, [currentYear, currentMonth]);

  const processBatches = async (batch) => {
    var bt = batch;

    for (var ind in bt) {
      if (bt[ind].recurring === 'true' || bt[ind].recurring === true) {
        var scd = generateSchedule(bt[ind]);
        bt[ind].schedule = scd;
      } else {
        var scd = [
          {
            date: bt[ind].date,
            hours: bt[ind].hours,
            minutes: bt[ind].minutes,
            time: bt[ind].time,
          },
        ];
        bt[ind].schedule = scd;
      }
    }

    const calendarDatesData = await getCalendarDates(currentYear, currentMonth);
    //setCalendarDates(calendarDatesData);

    const formattedDates = calendarDatesData.map((item) => {
      if (item) {
        return {
          date: item.date,
          isToday: isToday(item.date),
          isDifferentMonth: isDifferentMonth(item.date),
          schedule: item.schedule,
        };
      } else {
        return null;
      }
    });
    setFormattedCalendarDates(formattedDates);
    const c = formattedDates;
    for (var ind in c) {
      const itm = c[ind];
      if (itm) {
        itm.schedule = [];
        var preSch = [];
        for (var ind in bt) {
          for (var inds in bt[ind].schedule) {
            if (
              bt[ind].schedule[inds].date ===
              moment(itm.date).format('YYYY-MM-DD')
            ) {
              var sc = bt[ind].schedule[inds];
              for (var k in bt[ind]) {
                if (!sc[k]) {
                  sc[k] = bt[ind][k];
                }
              }
              preSch.push(sc);
            }
          }
        }
        const batches = organizeBatches(preSch);
        itm.schedule = batches;
      }
    }
    setBatch(bt);
    setCalendarDates(formattedDates);
  };
  const getCourses = async () => {
    setLoading(true);

    const collection = 'courses';
    const filter = { status: 'true' };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };
  const getTeachers = async () => {
    setLoading(true);

    const collection = 'teachers';
    const filter = { status: 'true' };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setTeachers(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getLanguages = async () => {
    setLoading(true);

    const collection = 'languages';
    const filter = { status: 'true' };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setLanguages(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getCenters = async () => {
    setLoading(true);

    const collection = 'centers';
    const filter = { status: 'true' };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setCenters(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getBatch = async (coursesId) => {
    setLoading(true);
    const collection = 'batch';
    const filter = {
      approval_status: 'approved',
      'course._id': { $in: coursesId },
    };
    const sort = { course: 1 };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setBatch(response.data);
      processBatches(response.data);
      setsCurrentWeek();
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getColor = (c, d) => {
    for (var ind in courses) {
      if (courses[ind].title === c) {
        var hex = courses[ind].color.replace('#', '');
        const opacity = d ? 1 : 0.2;
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getCourses();
      await getTeachers();
      await getLanguages();
      await getCenters();
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async (coursesId) => {
      setLoading(true);
      await getBatch(coursesId);
      setLoading(false);
    };
    if (Array.isArray(courses) && courses.length) {
      var coursesId = [];
      courses.map((item) => coursesId.push(item._id));
      fetchData(coursesId);
    }
  }, [courses]);

  const getCalendarDates = async (year, month) => {
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);
    const startDayIndex = firstDayOfMonth.getDay();
    const endDay = lastDayOfMonth.getDate();

    const calendarDates = [];

    // Fill in the days before the first day of the month with null
    for (let i = 0; i < startDayIndex; i++) {
      calendarDates.push(null);
    }

    // Fill in the days of the month with date objects
    for (let i = 1; i <= endDay; i++) {
      calendarDates.push({ date: new Date(year, month, i) });
    }

    // Optionally, fill the remaining days of the last week with null to complete the grid
    const totalDays = calendarDates.length;
    const remainingDays = 7 - (totalDays % 7);
    if (remainingDays < 7) {
      for (let i = 0; i < remainingDays; i++) {
        calendarDates.push(null);
      }
    }

    if (thisWeek < 1) {
      const lastWeek = calendarDates.length / 7;
      setThisWeek(lastWeek);
    }
    if (thisDay < 1 && viewMode === 'day') {
      var lastDay = 0;
      calendarDates.map((item) => {
        if (item) {
          lastDay = lastDay + 1;
        }
      });
      /* debugger; */

      setThisDay(lastDay);
      /*  setTimeout(() => {}, 1000); */
    }

    return calendarDates;
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isDifferentMonth = (date) => {
    return date.getMonth() !== currentMonth;
  };

  const prevMonth = async () => {
    if (currentMonth === 0) {
      setCurrentYear((prevYear) => prevYear - 1);
      setCurrentMonth(11);
    } else {
      setCurrentMonth((prevMonth) => prevMonth - 1);
    }
  };
  const [currentWeekStart, setCurrentWeekStart] = useState('');
  const [currentWeekEnd, setCurrentWeekEnd] = useState('');
  const nextMonth = async () => {
    if (currentMonth === 11) {
      setCurrentYear((prevYear) => prevYear + 1);
      setCurrentMonth(0);
    } else {
      setCurrentMonth((prevMonth) => prevMonth + 1);
    }
  };

  const switchToMonthView = () => {
    // Implement your logic here
    setViewMode('month');
  };

  const switchToWeekView = () => {
    // Implement your logic here
    setViewMode('week');
  };

  const switchToListView = () => {
    // Implement your logic here
    setViewMode('day');
  };
  const setCurrentDate = (d) => {
    setCurrentDay({
      day: moment(d).format('DD'),
      week: moment(d).format('ddd'),
      dated: moment(d).format('MMMM Do YYYY'),
    });
    //setCurrentWeek()
  };
  const getSchedule = (d) => {
    var sc = [];
    if (d) {
      for (var ind in calendarDates) {
        if (calendarDates[ind]) {
          var t = moment(calendarDates[ind].date).format('DD-MM-YYYY');
          var r = moment(d, 'DD-MM-YYYY').format('DD-MM-YYYY');
          if (t == r && calendarDates[ind].schedule) {
            sc = calendarDates[ind].schedule;
          }
        }
      }
    }

    return sc;
  };
  const [selectedBatch, setSelectedBatch] = useState({});
  const setCurrentBatch = (q, dated, f) => {
    const currentDated = f
      ? moment(dated, 'DD-MM-YYYY').format('MMMM Do YYYY')
      : moment(dated).format('MMMM Do YYYY');
    // Assuming selectedBatch is a state variable in React
    setSelectedBatch({ ...q, currentDated });
  };
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });

  const togglePopup = (event) => {
    // Toggle popup visibility
    setPopShow(!popShow);

    // Get the position of the parent element relative to the viewport
    const parentRect = event.target.offsetParent.getBoundingClientRect();

    // Get the mouse click coordinates relative to the viewport
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    // Calculate the position of the popup relative to the parent element
    const popupX = mouseX - parentRect.left;
    const popupY = mouseY - parentRect.top;

    // Set the popup position
    setPopupPosition({
      x: popupX,
      y: popupY,
    });
  };
  const [search, setSearch] = useState('');

  const setsCurrentWeek = (date) => {
    // Get the current date
    const propsDate = date ? new Date(date) : currentDay.dated;
    const currentDate = moment(propsDate, 'MMMM Do YYYY');
    // Calculate the start date of the current week (Sunday)
    const startDate = currentDate.startOf('week');
    // Generate the current week array with date objects formatted as "dd-mm-yyyy"
    const week = [];
    for (let i = 0; i < 7; i++) {
      const formattedDate = startDate.clone().add(i, 'days');
      week.push({
        date: formattedDate.format('DD-MM-YYYY'), // Format date as "dd-mm-yyyy",
        day: formattedDate.format('DD'), // Format date as "dd-mm-yyyy"
        month: formattedDate.format('MM'), // Format date as "dd-mm-yyyy"
        year: formattedDate.format('YYYY'), // Format date as "dd-mm-yyyy"
      });
    }

    // Update the CurrentWeek ref
    //setCurrentWeek(week);
  };

  const [thisWeek, setThisWeek] = useState(1);
  const [thisDay, setThisDay] = useState(1);
  const [thisDayData, setThisDayData] = useState(null);

  useEffect(() => {
    if (viewMode === 'week') {
      onWeekChange(thisWeek);
    }
    if (viewMode === 'day') {
      onDayChange(thisDay);
    }
  }, [thisDay, thisWeek, formattedCalendarDates, viewMode]);

  const onWeekChange = async (thisWeek) => {
    const totalDays = formattedCalendarDates.length;
    const totalWeeks = totalDays / 7;
    if (thisWeek <= totalWeeks && thisWeek > 0) {
      var arr = [];
      for (var i = 0; i < 7; i++) {
        arr.push(formattedCalendarDates[(thisWeek - 1) * 7 + i]);
      }
      setCurrentWeek(arr);
      /*  debugger; */
    } else if (thisWeek < 1) {
      await prevMonth();
    } else {
      await nextMonth();
      setThisWeek(1);
    }
  };

  const onDayChange = async (thisDay) => {
    const fullMonth = [];
    formattedCalendarDates.map((item) => {
      if (item) {
        fullMonth.push(item);
      }
    });

    const totalDays = fullMonth.length;

    /* debugger; */

    if (thisDay <= totalDays && thisDay > 0) {
      const data = fullMonth[thisDay - 1];
      /* debugger; */
      setThisDayData(data);
    } else if (thisDay < 1) {
      await prevMonth();
      /* debugger; */
    } else {
      await nextMonth();
      setThisDay(1);
      /* debugger; */
    }
  };

  return (
    <div>
      <HomeComponentNew />
      <Header
        title="QC Calendar"
        description="You can check course schedules according to the calendar dates and timings."
      />
      <div className="grid grid-cols-12 gap-2 container">
        <div
          className={`col-span-2 items-start flex-col p-2   ${
            hideSide ? 'hidden' : 'sm:flex'
          }`}
        >
          <div className="flex justify-between items-center w-full mb-2">
            <h4 className="w-full mx-2 text-lg text-blue-500">
              {monthNames[currentMonth]} {currentYear}
            </h4>
            <div className="flex justify-between items-center">
              <svg
                onClick={prevMonth}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
              <svg
                onClick={nextMonth}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </div>

          <div className="grid grid-cols-7 grid-rows-1 w-full">
            {/* Weekday headers */}
            {weekDays.map((day, index) => (
              <div
                key={index}
                className="text-center font-semibold text-xs p-2 py-2 h-10 flex justify-center items-center"
              >
                {day}
              </div>
            ))}

            {/* Calendar dates */}
            {console.log('formattedCalendarDates', formattedCalendarDates)}
            {formattedCalendarDates.map((item, index) => (
              <div
                key={index}
                className={`text-center p-2 h-8 flex justify-center items-center flex-col ${
                  item &&
                  todayIs ==
                    moment(
                      `${item.date.getDate()}-${
                        item.date.getMonth() + 1
                      }-${item.date.getFullYear()}`,
                      'D-M-YYYY'
                    ).format('DD-MM-YYYY')
                    ? 'today'
                    : ''
                } ${
                  item &&
                  moment(
                    `${item.date.getDate()}-${
                      item.date.getMonth() + 1
                    }-${item.date.getFullYear()}`,
                    'D-M-YYYY'
                  ).format('MMMM Do YYYY') === currentDay.dated
                    ? 'selectedDay'
                    : ''
                }`}
                onClick={() => setCurrentDate(item.date)}
              >
                <p className="text-gray-600 text-xs cursor-pointer">
                  {item ? item.date.getDate() : ''}
                </p>
              </div>
            ))}
          </div>

          <div className="flex mt-10 justify-start items-center text-blue-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 mx-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
              />
            </svg>
            {currentDay.dated}
          </div>

          {formattedCalendarDates.map((i, d) => (
            <div key={d} className="w-full">
              {i && i.date.getDate() == currentDay.day && (
                <div className="w-full">
                  {i && (
                    <div className="w-full">
                      <p
                        className="p-4"
                        style={{
                          display:
                            (!loading && !i.schedule) || i.schedule === ''
                              ? 'block'
                              : 'none',
                        }}
                      >
                        No Schedule found
                      </p>
                      {i.schedule.map((qt, qtIndex) => (
                        <div
                          key={qtIndex}
                          className="w-full border-b border-green-400 pb-2"
                        >
                          {qt.batches.map((n, nIndex) => (
                            <div key={nIndex} className="w-full">
                              <div className="flex mt-5 justify-between items-center w-full pr-2">
                                <Link
                                  to={`/batch-list/${n.course}/${currentDay.dated}`}
                                  className="flex justify-start font-bold"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6 mx-2"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122"
                                    />
                                  </svg>
                                  {n.course}
                                </Link>
                                <p className="text-xs font-bold">
                                  {n.batches.length} Session
                                </p>
                              </div>
                              <Link
                                to={`/batch-list/${n.course?.title}/${currentDay.dated}`}
                                className="flex mt-2 justify-between items-center w-full pr-2"
                              >
                                <div className="flex justify-start mx-2 text-xs">
                                  {n.teacher}
                                </div>
                                <p className="text-xs flex text-gray-400 justify-center items-center">
                                  {n.time}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-4 h-4 ml-2"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                    />
                                  </svg>
                                </p>
                              </Link>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={` ${hideSide ? 'col-span-10' : 'col-span-8'}`}>
          <div className="flex flex-col sm:flex-row w-full mx-4 justify-between items-center">
            <div className="flex w-full sm:w-auto justify-start my-2 sm:my-0">
              <div className="sm:mx-4">
                <svg
                  onClick={() => setHideSide(!hideSide)}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 hidden sm:flex h-6 cursor-pointer mr-0 sm:mr-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </div>
              {viewMode === 'month' && (
                <div className="mx-4 w-full sm:w-auto">
                  <div className="text-xl font-semibold mr-10 flex w-full sm:w-auto sm:justify-start sm:items-start justify-between items-center">
                    <svg
                      onClick={prevMonth}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 mx-2 ml-0"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>
                    {monthNames[currentMonth]}{' '}
                    <span className="mr-1">
                      {viewMode === 'day' && `, ${currentDay.day}`}
                    </span>{' '}
                    {currentYear}
                    <svg
                      onClick={nextMonth}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 mx-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </div>
                </div>
              )}
              {viewMode === 'week' && calendarDates.length > 0 && (
                <div className="mx-4 w-full sm:w-auto">
                  <div className="text-xl font-semibold mr-10 flex w-full sm:w-auto sm:justify-start sm:items-start justify-between items-center">
                    <svg
                      onClick={() => setThisWeek((thisWeek) => thisWeek - 1)}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 mx-2 ml-0"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>
                    {monthNames[currentMonth]}{' '}
                    <span className="mr-1">
                      {viewMode === 'day' && `, ${currentDay.day}`}
                    </span>{' '}
                    {currentYear}
                    <svg
                      onClick={() => setThisWeek((thisWeek) => thisWeek + 1)}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 mx-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </div>
                </div>
              )}
              {viewMode === 'day' && calendarDates.length > 0 && (
                <div className="mx-4 w-full sm:w-auto">
                  <div className="text-xl font-semibold mr-10 flex w-full sm:w-auto sm:justify-start sm:items-start justify-between items-center">
                    <svg
                      onClick={() => setThisDay((thisDay) => thisDay - 1)}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 mx-2 ml-0"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>
                    {monthNames[currentMonth]}
                    <span className="mx-1">{thisDay}</span>
                    {currentYear}
                    <svg
                      onClick={() => setThisDay((thisDay) => thisDay + 1)}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 mx-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </div>
            <div className="border flex flex-row w-full sm:w-auto rounded-full p-2 my-2 sm:my-0">
              <button
                onClick={switchToMonthView}
                className={`px-4 py-2 mr-2 rounded-full w-full sm:w-[100px] ${
                  viewMode === 'month' ? 'bg-blue-500 text-white' : ''
                }`}
              >
                Month
              </button>
              <button
                onClick={switchToWeekView}
                className={`px-4 py-2 w-full sm:w-[100px] rounded-full ${
                  viewMode === 'week' ? 'bg-blue-500 text-white' : ''
                }`}
              >
                Week
              </button>
              <button
                onClick={switchToListView}
                className={`px-4 py-2 w-full sm:w-[100px] rounded-full ${
                  viewMode === 'day' ? 'bg-blue-500 text-white' : ''
                }`}
              >
                Day
              </button>
            </div>
            <div className="flex hidden sm:flex justify-end items-center border border-gray-300 rounded-full p-2 py-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
              <input
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search"
                className="border-0 w-[200px] text-sm no-border "
              />
              <Link to={`/search/${search}`}>Search</Link>
            </div>
          </div>

          {/* Calendar */}
          <div className="flex gap-0">
            {/* Render calendar based on viewMode */}
            {viewMode === 'month' && calendarDates.length > 0 && (
              // Render month view
              <div className="w-full">
                {/* Your month view JSX code here */}
                <div className="w-full">
                  {/* Weekday headers */}
                  <div className="grid grid-cols-7 gap-0">
                    {weekDays.map((day, index) => (
                      <div
                        key={index}
                        className="text-center font-semibold border-gray-100 p-2 py-2 h-12 border-bottom bg-gray-50 flex justify-center items-center bg-white"
                      >
                        {day}
                      </div>
                    ))}
                  </div>
                  {/* Calendar dates */}
                  <div className="grid grid-cols-7 gap-0">
                    {formattedCalendarDates.map((item, index) => (
                      <div
                        key={index}
                        className="text-center border border-gray-100 p-2 min-h-40 flex justify-between items-start flex-col bg-white"
                      >
                        <p className="text-gray-600">
                          {item ? item.date.getDate() : ''}
                        </p>
                        {item && (
                          <div className="schedule flex justify-start items-start flex-wrap">
                            {item.schedule &&
                              item.schedule.map((qt, qtIndex) => (
                                <div
                                  onClick={togglePopup}
                                  key={qtIndex}
                                  className="w-full flex flex-wrap"
                                >
                                  {qt.batches.map((bt, btIndex) => (
                                    <div
                                      key={btIndex}
                                      className="mx-1 my-1 cursor-pointer ml-0 flex justify-start items-center"
                                      onClick={() => {
                                        setCurrentBatch(bt, item.date);
                                      }}
                                    >
                                      <span
                                        style={{
                                          backgroundColor: getColor(bt.course),
                                        }}
                                        className="bg-gray-200 justify-start items-center flex rounded-full border text-xs p-1 border rounded px-2"
                                      >
                                        <span
                                          style={{
                                            backgroundColor: getColor(
                                              bt.course,
                                              1
                                            ),
                                          }}
                                          className="rd mr-1"
                                        ></span>
                                        {bt.course}
                                      </span>
                                      {bt.batches.length > 1 && (
                                        <span className="bdg">
                                          {' '}
                                          +{bt.batches.length - 1}
                                        </span>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {viewMode === 'week' && calendarDates.length > 0 && (
              // Render week view

              <div className="grid grid-cols-7 gap-0 w-full mx-8">
                {/* Weekday headers */}
                {weekDays.map((day) => (
                  <div
                    key={day}
                    className="text-center font-semibold p-2 py-2 h-12 border-gray-100 border-bottom bg-gray-50 flex justify-center items-center bg-white"
                  >
                    {day}
                  </div>
                ))}

                {/* Calendar dates */}
                {currentWeek.map((item, index) => (
                  <div
                    key={index}
                    className="text-center border border-gray-100 p-2 full-height flex justify-start items-start flex-col bg-white"
                  >
                    <p className="text-gray-600">
                      {item?.date ? moment(item?.date).format('DD') : ''}
                    </p>
                    <div className="schedule flex justify-start items-start flex-wrap">
                      {item && item?.date && (
                        <div className="w-full flex flex-wrap">
                          {getSchedule(item.date).map((qt) => (
                            <div
                              onClick={togglePopup}
                              key={qt}
                              className="mx-1 cursor-pointer my-1 ml-0 flex flex-wrap justify-start items-center"
                            >
                              {qt.batches.map((bt) => (
                                <div
                                  key={bt.course}
                                  onClick={() =>
                                    setCurrentBatch(bt, item.date, true)
                                  }
                                  className="flex items-center"
                                >
                                  <span
                                    style={{
                                      backgroundColor: getColor(bt.course),
                                    }}
                                    className="bg-gray-200 justify-start items-center flex rounded-full border text-xs p-1 border rounded px-2"
                                  >
                                    <span
                                      style={{
                                        backgroundColor: getColor(bt.course, 1),
                                      }}
                                      className="rd mr-1"
                                    ></span>
                                    {bt.course}
                                  </span>
                                  {bt.batches.length > 1 && (
                                    <span className="bdg">
                                      +{bt.batches.length - 1}
                                    </span>
                                  )}
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
            {viewMode === 'day' && calendarDates.length > 0 && (
              // Render day view
              <div className="grid grid-cols-1 gap-0 w-full">
                {/* Weekday headers */}
                {weekDays.map((day) => (
                  <div key={day} className="w-full">
                    {day === currentDay.week && (
                      <div className="text-center font-semibold  p-2 py-2 h-24 border-bottom bg-gray-50 flex justify-center items-center bg-white">
                        {currentDay.week} - {currentDay.dated}
                      </div>
                    )}
                  </div>
                ))}

                {/* Calendar dates */}
                {thisDayData && (
                  <div className="w-full">
                    {thisDayData && thisDayData.date && (
                      <div className="text-center border b-gray-100 p-2 flex justify-start items-start flex-col bg-white">
                        <div className="schedule flex justify-start items-start flex-wrap w-full">
                          <p
                            className="p-4"
                            style={{
                              display:
                                (!loading && !thisDayData.schedule) ||
                                thisDayData.schedule === ''
                                  ? 'block'
                                  : 'none',
                            }}
                          >
                            No Schedule found
                          </p>
                          {thisDayData.schedule &&
                            thisDayData.schedule.map((qt, qtIndex) => (
                              <div key={qtIndex} className="p-4 w-full mb-4">
                                {qt.batches.map((n, nIndex) => (
                                  <div
                                    key={nIndex}
                                    className="ml-l"
                                    style={{ borderColor: getColor(n.course) }}
                                  >
                                    <div className="flex border-b border-gray-200 pb-4 justify-between items-center font-bold">
                                      <Link
                                        to={`/batch-list/${n.course}/${currentDay.dated}`}
                                        className="flex justify-start"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          stroke="currentColor"
                                          className="w-6 h-6 mx-2"
                                          style={{
                                            color: getColor(n.course, 1),
                                          }}
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122"
                                          />
                                        </svg>
                                        <h4
                                          style={{
                                            color: getColor(n.course, 1),
                                          }}
                                        >
                                          {n.course}
                                        </h4>
                                      </Link>
                                    </div>
                                    <div className="flex flex-row">
                                      {n.batches.map((m, mIndex) => (
                                        <Link
                                          to={`/batch-list/${n.course}/${currentDay.dated}/${m._id}`}
                                          key={mIndex}
                                          className="w-full"
                                        >
                                          {mIndex < 5 && (
                                            <div className="w-full flex flex-col border-gray-500 pb-3 mb-3">
                                              <div className="flex mt-2 justify-start items-start pr-2 flex-col">
                                                <div className="flex justify-start mx-2 text-sm font-bold">
                                                  {m?.teacher?.name}
                                                </div>
                                                <p
                                                  className="bgd flex justify-center items-center bg-blue-800 text-white rounded-full p-2 text-xxs py-1 my-2"
                                                  style={{
                                                    display: m.batch_no
                                                      ? 'block'
                                                      : 'none',
                                                  }}
                                                >
                                                  BATCH NO {m.batch_no}
                                                </p>
                                              </div>
                                              <div className="flex mt-2 justify-start items-center pr-2">
                                                <div className="flex justify-start mx-2 text-xs">
                                                  {m.language}
                                                </div>
                                                <div className="dots"></div>
                                                <p className="xxs flex justify-center items-center rounded-md p-2 text-xxs py-1">
                                                  {m.location}
                                                </p>
                                              </div>
                                              <div className="flex mt-2 justify-start items-center pr-2">
                                                <div className="flex justify-start items-center mx-2 text-xs ">
                                                  {m.time}{' '}
                                                  <div className="dots mx-2"></div>{' '}
                                                  {m.hours} Hour {m.minutes}{' '}
                                                  Minutes
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </Link>
                                      ))}
                                      {n.batches.length > 5 && (
                                        <button className="bg-[#2271b1] text-white rounded-md p-2 w-full">
                                          {n.batches.length - 5} More Courses
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {popShow && (
        <div
          style={{ top: popupPosition.y + 'px', left: popupPosition.x + 'px' }}
          className={`popup w-[400px] p-4 absolute h-auto top-0 bottom-0 right-0 ${
            popShow ? 'block' : 'hidden'
          }`}
        >
          <div className="popup bg-white rounded-md bg-white p-4 shadow-md border">
            <div className="flex border-b border-gray-400 pb-4 justify-between items-center font-bold">
              <Link
                to={`/batch-list/${selectedBatch.course}/${selectedBatch.currentDated}`}
                className="flex justify-start text-blue-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 mx-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122"
                  />
                </svg>
                <h4>{selectedBatch.course}</h4>
              </Link>
              <div>
                <svg
                  onClick={() => setPopShow(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 cursor-pointer"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div className="w-full">
              {selectedBatch.batches.map((b, index) => (
                <Link
                  to={`/batch-list/${selectedBatch.course}/${selectedBatch.currentDated}/${b._id}`}
                  key={index}
                  className="w-full border-b border-dotted border-gray-500 pb-3 mb-3"
                >
                  {index < 5 && (
                    <>
                      <div className="flex mt-2 justify-between items-center w-full pr-2">
                        <div className="flex justify-start mx-2 text-sm font-bold">
                          {b.teacher?.name}
                        </div>
                        {b.batch_no && (
                          <p className="bgd flex justify-center items-center bg-blue-800 text-white rounded-full p-2 text-xxs py-1">
                            BATCH NO {b.batch_no}
                          </p>
                        )}
                      </div>
                      <div className="flex mt-2 justify-between items-center w-full pr-2">
                        <div className="flex justify-start mx-2 text-xs">
                          {b.language}
                        </div>
                        <p className="xxs flex justify-center items-center rounded-md p-2 text-xxs py-1">
                          {b.location}
                        </p>
                      </div>
                      <div className="flex mt-2 justify-between items-center w-full pr-2">
                        <div className="flex justify-start mx-2 text-xs font-bold">
                          {b.time} . {b.hours} Hour {b.minutes} Minutes
                        </div>
                      </div>
                    </>
                  )}
                </Link>
              ))}
            </div>
            {selectedBatch.batches.length > 5 && (
              <button className="bg-[#2271b1] text-white rounded-md p-2 w-full">
                {selectedBatch.batches.length - 5} More Courses
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CalendarComponent;
