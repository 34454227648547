import axios from 'axios';
import { settings } from './Settings';
import { Header } from './components/Header';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams, Link } from 'react-router-dom';
import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material';
import TextfieldNoborder from './components/TextfieldNoborder';

const apiURL = settings.baseUrl;
const imageUriPath = settings.imageUriPath;
const db = settings.MongodbAppName;

function AdvanceSearchComponent() {
  const { course } = useParams();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({
    repeat: '',
    teacherName: '',
    assistantTeacer: '',
    courseTitle: '',
    batchNo: '',
    address: '',
    language: '',
    center: '',
    searchText: '',
    startDate: moment().format('YYYY-MM-DD'),
    location: '',
    endDate: moment().add(1, 'months').format('YYYY-MM-DD'),
  });
  const [sortedBatches, setSortedBatches] = useState([]);
  const [batch, setBatch] = useState([]);
  const [courses, setCourses] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [centers, setCenters] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    setLoading(true);

    getBatch();
    init();
  }, []);

  useEffect(() => {
    var temBatches = {};
    batch.map((item) => {
      if (!temBatches[item?.course.title]) {
        temBatches[item?.course.title] = [];
      }
      temBatches[item?.course.title].push(item);
    });
    console.log(temBatches);

    setSortedBatches(sortObject(temBatches));
  }, [batch]);

  function sortObject(obj) {
    return Object.keys(obj)
      .sort()
      .reduce(function (result, key) {
        result[key] = obj[key];
        return result;
      }, {});
  }

  const init = () => {
    getCourses();
    getCenters();
    getLanguages();
    getTeachers();
  };

  const applySearch = (searchText = '') => {
    getBatch(searchText);
  };

  const resetSearch = () => {
    setSearch({
      repeat: '',
      teacherName: '',
      assistantTeacer: '',
      courseTitle: '',
      batchNo: '',
      address: '',
      language: '',
      center: '',
      searchText: '',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(2, 'weeks').format('YYYY-MM-DD'),
    });
    setShowFilter(false);
    setLoading(true);

    getBatch();
  };

  const dayReturn = (d) => {
    return moment(d).format('DD');
  };

  const restReturn = (d) => {
    return moment(d).format('MMM YYYY,ddd');
  };

  const getCourses = async () => {
    setLoading(true);

    const collection = 'courses';
    const filter = { status: 'true' };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };
  const getTeachers = async () => {
    setLoading(true);

    const collection = 'teachers';
    const filter = { status: 'true' };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      if (response.data && Array.isArray(response.data)) {
        setTeachers(
          [...response.data].sort((a, b) => a.name.localeCompare(b.name))
        );
      } else setTeachers(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getLanguages = async () => {
    setLoading(true);

    const collection = 'languages';
    const filter = { status: 'true' };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setLanguages(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getCenters = async () => {
    setLoading(true);

    const collection = 'centers';
    const filter = { status: 'true' };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setCenters(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getBatch = async (searchText = '') => {
    const collection = 'batch';
    const filter = {
      startDate: '2024-01-01',
      endDate: '2024-12-31',
    };

    if (search.startDate)
      filter.startDate = moment(search.startDate).format('YYYY-MM-DD');
    if (search.endDate)
      filter.endDate = moment(search.endDate).format('YYYY-MM-DD');
    if (search.repeat) filter.repeat = search.repeat;
    if (search.teacherName) filter.teacherName = search.teacherName;
    if (search.assistantTeacer) filter.assistantTeacer = search.assistantTeacer;
    if (search.courseTitle) filter.courseTitle = search.courseTitle;
    if (search.batchNo) filter.batchNo = search.batchNo;
    if (search.address) filter.address = search.address;
    if (search.language) filter.language = search.language;
    if (search.center) filter.center = search.center;
    if (search.teacher) filter.teacher = search.teacher;
    if (searchText) filter.searchText = searchText;
    else if (search.searchText) filter.searchText = search.searchText;
    if (search.location) filter.searchText = search.location;

    const queryParams = new URLSearchParams({
      db,
      collection,
      ...filter,
    });
    try {
      const response = await axios.get(
        `/batches/search/advance?${queryParams.toString()}`,
        {
          baseURL: apiURL,
        }
      );
      setBatch(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };
  // Handle search criteria change
  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  // Handle apply search button click

  // Handle reset search button click

  // Helper function to return day

  // Helper function to return rest

  // JSX markup for rendering the component

  const getColor = (c, d) => {
    var hex = c.color.replace('#', '');
    const opacity = d ? 1 : 0.2;
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  return (
    <div>
      <Header
        title="COURSE SEARCH"
        description="You can check course schedules according to the calendar dates and timings."
      />
      <div>
        {/* Search and Filter UI */}
        <div className="container">
          <div className="flex justify-start items-start px-4 sm:px-auto sm:justify-start flex-col sm:flex-row sm:items-center mb-10 container">
            {/* Search */}
            <div className="flex mb-2 sm:mb-0">
              <h4 className="text-xl font-bold mr-4">Search </h4>
            </div>

            {/* Search input and buttons */}
            <form
              className="w-full"
              onSubmit={(e) => {
                e.preventDefault();
                applySearch('');
              }}
            >
              <div className="flex justify-start items-center border border-gray-300 rounded-full p-2 py-1 w-full">
                {/* Search icon SVG */}
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                  ></path>
                </svg> */}

                {/* Search input */}
                <input
                  type="text"
                  placeholder="Search Course"
                  value={search.searchText}
                  onChange={(e) => {
                    setSearch({ ...search, searchText: e.target.value });
                  }}
                  className="no-border w-full text-sm rounded-full"
                />

                {/* Clear search button */}
                {search.searchText && (
                  <span
                    onClick={() => setSearch({ ...search, searchText: '' })}
                    className="w-10 text-center cursor-pointer px-2"
                  >
                    X
                  </span>
                )}

                <button className="mr-4" type="submit">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    ></path>
                  </svg>
                </button>

                {/* Apply search button */}
              </div>
            </form>

            {/* Filter button */}
            <button
              onClick={() => setShowFilter(true)}
              className="sm:mx-2 p-2 bg-white border rounded-full px-6 flex w-full sm:w-auto my-4 sm:my-auto"
            >
              {/* Filter icon SVG */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 mx-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                ></path>
              </svg>
              Filter
            </button>
          </div>

          {/* Filter options */}
          {showFilter && (
            <div>
              <div className="px-5 py-2 mt-1 flex flex-col gap-3.5">
                <div className="grid grid-cols-6 gap-3.5">
                  <div className="sm:col-span-2 col-span-6 mt-2">
                    <label className="inline-block mb-2">Course</label>
                    <select
                      name="courseTitle"
                      value={search.courseTitle}
                      onChange={handleSearchChange}
                      className="bg-[length:20px_auto] disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 bg-chevron-black transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50  sm:mr-2"
                      aria-label="Select Course"
                    >
                      <option value="">All Courses</option>
                      {courses.map((course) => (
                        <option key={course._id} value={course.title}>
                          {course.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="sm:col-span-2 col-span-6 mt-2">
                    <label className="inline-block mb-2">Teacher</label>
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="country-select-demo"
                      onChange={(e, value) => {
                        console.log(value);
                        if (value) {
                          handleSearchChange({
                            target: { name: 'teacherId', value: value._id },
                          });
                          handleSearchChange({
                            target: { name: 'teacherName', value: value.name },
                          });
                        } else {
                          handleSearchChange({
                            target: { name: 'teacherId', value: '' },
                          });
                          handleSearchChange({
                            target: { name: 'teacherName', value: '' },
                          });
                        }
                      }}
                      value={
                        teachers.find(
                          (item) => item._id === search.teacherId
                        ) || null
                      }
                      options={teachers}
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <Box key={key} component="li" {...optionProps}>
                            {option.name}
                          </Box>
                        );
                      }}
                      renderInput={(params) => (
                        <TextfieldNoborder
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    {/* <select
                      name="teacherName"
                      value={search.teacherName}
                      onChange={handleSearchChange}
                      className="bg-[length:20px_auto] disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 bg-chevron-black transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50  sm:mr-2"
                      aria-label="Select Teacher"
                    >
                      <option value="">All Teachers</option>
                      {teachers.map((teacher) => (
                        <option key={teacher.id} value={teacher.name}>
                          {teacher.name}
                        </option>
                      ))}
                    </select> */}
                  </div>
                  <div className="sm:col-span-2 col-span-6 mt-2">
                    <label className="inline-block mb-2">Centers</label>
                    <select
                      name="center"
                      value={search.center}
                      onChange={handleSearchChange}
                      className="bg-[length:20px_auto] disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 bg-chevron-black transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50  sm:mr-2"
                      aria-label="Select Teacher"
                    >
                      <option value="">All Centers</option>
                      {centers.map((center) => (
                        <option key={center._id} value={center.title}>
                          {center.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="sm:col-span-2 col-span-6 mt-2">
                    <label className="inline-block mb-2">
                      Assistant Teacer
                    </label>
                    <input
                      name="assistantTeacer"
                      value={search.assistantTeacer}
                      onChange={handleSearchChange}
                      type="text"
                      placeholder="Assistant Teacer"
                      className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80"
                    />
                  </div>
                  <div className="sm:col-span-2 col-span-6 mt-2">
                    <label className="inline-block mb-2">Batch Number</label>
                    <input
                      name="batchNo"
                      value={search.batchNo}
                      onChange={handleSearchChange}
                      type="text"
                      placeholder="Batch Number"
                      className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80"
                    />
                  </div>
                  <div className="sm:col-span-2 col-span-6 mt-2">
                    <label className="inline-block mb-2">Address</label>
                    <input
                      name="address"
                      value={search.address}
                      onChange={handleSearchChange}
                      type="text"
                      placeholder="Address"
                      className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80"
                    />
                  </div>
                  <div className="sm:col-span-2 col-span-6 mt-2">
                    <label className="inline-block mb-2">
                      Mode of Teaching
                    </label>
                    <select
                      name="location"
                      value={search.location}
                      onChange={handleSearchChange}
                      className="bg-[length:20px_auto] disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 bg-chevron-black transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50  sm:mr-2"
                      aria-label="Select Language"
                    >
                      <option value="">Select Mode of Teaching</option>
                      <option>Online</option>
                      <option>Physical</option>
                      <option>Online & Physical</option>
                    </select>
                  </div>
                  <div className="sm:col-span-2 col-span-6 mt-2">
                    <label className="inline-block mb-2">Language</label>
                    <select
                      name="language"
                      value={search.language}
                      onChange={handleSearchChange}
                      className="bg-[length:20px_auto] disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 bg-chevron-black transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50  sm:mr-2"
                      aria-label="Select Language"
                    >
                      <option value="">All Languages</option>
                      {languages.map((language) => (
                        <option key={language.id} value={language.title}>
                          {language.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="sm:col-span-2 col-span-6 mt-2">
                    <label className="inline-block mb-2">
                      Course Start Date From
                    </label>
                    <input
                      name="startDate"
                      value={search.startDate}
                      onChange={handleSearchChange}
                      type="date"
                      placeholder="Course Start Date From"
                      className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80"
                    />
                  </div>
                  <div className="sm:col-span-2 col-span-6 mt-2">
                    <label className="inline-block mb-2">
                      Course Start Date To
                    </label>
                    <input
                      name="endDate"
                      value={search.endDate}
                      onChange={handleSearchChange}
                      type="date"
                      placeholder="Course Start Date To"
                      className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80"
                    />
                  </div>
                  <div className="sm:col-span-2 col-span-6 mt-2">
                    <label className="inline-block mb-2">Repeat</label>
                    <select
                      name="repeat"
                      value={search.repeat}
                      onChange={handleSearchChange}
                      className="bg-[length:20px_auto] disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 bg-chevron-black transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50  sm:mr-2"
                      aria-label="Select Teacher"
                    >
                      <option value={''}>Select</option>
                      <option value={'daily'}>Daily</option>
                      <option value={'weekly'}>Weekly</option>
                      <option value={'monthly'}>Monthly</option>
                    </select>
                  </div>
                </div>
                <div className="px-5 py-2 mt-4 flex gap-3.5 w-full flex-row items-end justify-end mb-10">
                  {/* Apply Search button */}
                  <button
                    onClick={() => applySearch('')}
                    className="transition duration-200 border shadow-sm items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-[#3b5998] border-[#3b5998] text-white dark:border-[#3b5998] w-32"
                  >
                    Search
                  </button>
                  {/* Reset and Close button */}
                  <button
                    onClick={resetSearch}
                    className="transition duration-200 border shadow-sm items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none dark:focus:ring-slate-700 dark:focus:ring-opacity-50 [&amp;:hover:not(:disabled)]:bg-opacity-90 [&amp;:hover:not(:disabled)]:border-opacity-90 [&amp;:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-white border-[#3b5998] text-[#3b5998] dark:border-[#3b5998] w-32"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>{/* Your search and filter UI here */}</div>

        {/* Batch Display */}

        <div>
          <div className="w-full">
            <div className="text-center border b-gray-100 p-2 flex justify-start items-start flex-col bg-white">
              {sortedBatches &&
                Object.keys(sortedBatches).map((courseKey, nIndex) => (
                  <div
                    key={nIndex}
                    className="ml-l mb-12 w-full"
                    style={{
                      borderColor: getColor(sortedBatches[courseKey][0].course),
                    }}
                  >
                    <div
                      style={{
                        color: getColor(sortedBatches[courseKey][0].course, 1),
                      }}
                      className="font-bold text-3xl text-start my-6 "
                    >
                      {courseKey}
                    </div>
                    <hr className="mb-2" />
                    <Grid container spacing={3}>
                      {sortedBatches[courseKey].map((batch, bIndex) => (
                        <Grid item lg={4} sm={6} xs={12}>
                          <Link
                            to={`/batch-list/${batch.course?.title}/${batch.date}/${batch._id}`}
                            key={bIndex}
                            className="w-full"
                          >
                            <div className="flex gap-4">
                              <img
                                src={`${imageUriPath}${batch.imagePath}`}
                                style={{
                                  height: '200px',
                                  width: '150px',
                                }}
                              />
                              <div className="flex-1 w-full flex flex-col border-gray-500 pb-3 mb-3">
                                <div className="flex mt-2 justify-start items-start pr-2 flex-col">
                                  <div className="flex justify-start mx-2 text-lg font-bold">
                                    {batch?.teacher?.name}
                                  </div>
                                  <div className="flex gap-2">
                                    {batch.batch_no && (
                                      <p className="bgd flex justify-center items-center bg-blue-800 text-white rounded-full p-2 text-xs py-1">
                                        BATCH NO {batch.batch_no}
                                      </p>
                                    )}
                                    {batch.date &&
                                      moment(batch.date).isAfter() &&
                                      batch.registrationLink && (
                                        <a
                                          onClick={(e) => {
                                            e.preventDefault();
                                            window.open(
                                              batch.registrationLink,
                                              '_blank'
                                            );
                                          }}
                                          href={batch.registrationLink}
                                          target="_blank"
                                          className="bgd flex justify-center items-center bg-green-500 text-white rounded-full p-2 text-xs py-1"
                                        >
                                          Enroll now
                                        </a>
                                      )}
                                  </div>
                                </div>
                                <div className="flex mt-2 justify-start items-center pr-2">
                                  <div className="flex justify-start mx-2 text-sm">
                                    {batch.language}
                                  </div>
                                  <div className="dots"></div>
                                  <p className="xxs flex justify-center items-center rounded-md p-2 text-sm py-1">
                                    {batch.location}
                                  </p>
                                </div>
                                <div className="flex mt-2 justify-start items-center pr-2">
                                  <div className="flex justify-start items-center mx-2 text-sm ">
                                    {batch.time} {batch.timeAt}{' '}
                                    <div className="dots mx-2"></div>{' '}
                                    {batch.hours} Hour {batch.minutes} Minutes
                                  </div>
                                </div>

                                <div className="mx-2">
                                  {batch.date &&
                                  moment(batch.date).isAfter() ? (
                                    <div className="flex justify-start items-center text-sm  mt-2">
                                      Start On
                                      <div className="dots mx-2"></div>{' '}
                                      {moment(batch.date).format('Do MMM YYYY')}
                                    </div>
                                  ) : (
                                    batch.date && (
                                      <div className="flex justify-start items-center text-sm  mt-2">
                                        Started On
                                        <div className="dots mx-2"></div>{' '}
                                        {moment(batch.date).format(
                                          'Do MMM YYYY'
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                                {batch.date &&
                                  moment(batch.date).isAfter() &&
                                  batch.registrationLink && (
                                    <div className="mx-2">
                                      <div className="animate-updown text-green-700 font-bold text-start mt-4">
                                        Upcoming Course
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default AdvanceSearchComponent;
