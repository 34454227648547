import axios from 'axios';
import { settings } from './Settings';
import { Header } from './components/Header';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Box, Grid, Popover } from '@mui/material';

const apiURL = settings.baseUrl;
const imageUriPath = settings.imageUriPath;
const db = settings.MongodbAppName;

function HomeComponentNew() {
  const [formattedCalendarDates, setFormattedCalendarDates] = useState([]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const navigate = useNavigate();
  const [currentDay, setCurrentDay] = useState({
    day: moment().format('DD'),
    week: moment().format('ddd'),
    dated: moment().format('MMMM Do YYYY'),
  });
  const [todayIs, setTodayIs] = useState(moment().format('DD-MM-YYYY')); // Store current date

  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const [hideSide, setHideSide] = useState(false);
  const [currentWeek, setCurrentWeek] = useState([]);
  const [viewMode, setViewMode] = useState('month');
  const [popShow, setPopShow] = useState(false);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  const organizeBatches = (arr) => {
    const organized = {};

    // Iterate through each object in the array
    arr.forEach((obj) => {
      const {
        date,
        course: { title },
      } = obj;

      // If the date doesn't exist in the organized object, create an empty array for it
      if (!organized[date]) {
        organized[date] = {};
      }

      // If the course doesn't exist in the organized object for this date, create an empty array for it
      if (!organized[date][title]) {
        organized[date][title] = [];
      }

      // Push the object to the appropriate batch array
      organized[date][title].push(obj);
    });

    // Convert the organized object into an array of objects
    const result = [];
    for (const date in organized) {
      const batches = [];
      for (const course in organized[date]) {
        batches.push({
          course: course,
          batches: organized[date][course],
        });
      }
      result.push({
        date: date,
        batches: batches,
      });
    }

    return result;
  };

  useEffect(() => {
    getCourses();
  }, []);
  const getCourses = async () => {
    setLoading(true);

    const collection = 'courses';
    const filter = { status: 'true' };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };

  const getColor = (c, d) => {
    for (var ind in courses) {
      if (courses[ind].title === c) {
        var hex = courses[ind].color.replace('#', '');
        const opacity = d ? 1 : 0.2;
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
      }
    }
  };

  const getCalendarDates = async (year, month) => {
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);
    const startDayIndex = firstDayOfMonth.getDay();
    const endDay = lastDayOfMonth.getDate();

    const calendarDates = [];

    // Fill in the days before the first day of the month with null
    for (let i = 0; i < startDayIndex; i++) {
      calendarDates.push(null);
    }

    // Fill in the days of the month with date objects
    for (let i = 1; i <= endDay; i++) {
      calendarDates.push({ date: new Date(year, month, i) });
    }

    // Optionally, fill the remaining days of the last week with null to complete the grid
    const totalDays = calendarDates.length;
    const remainingDays = 7 - (totalDays % 7);
    if (remainingDays < 7) {
      for (let i = 0; i < remainingDays; i++) {
        calendarDates.push(null);
      }
    }

    if (thisWeek < 1) {
      const lastWeek = calendarDates.length / 7;
      setThisWeek(lastWeek);
    }
    if (thisDay < 1 && viewMode === 'day') {
      var lastDay = 0;
      calendarDates.map((item) => {
        if (item) {
          lastDay = lastDay + 1;
        }
      });
      /* debugger; */

      setThisDay(lastDay);
      /*  setTimeout(() => {}, 1000); */
    }

    return calendarDates;
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isDifferentMonth = (date) => {
    return date.getMonth() !== currentMonth;
  };

  const prevMonth = async () => {
    if (currentMonth === 0) {
      setCurrentYear((prevYear) => prevYear - 1);
      setCurrentMonth(11);
    } else {
      setCurrentMonth((prevMonth) => prevMonth - 1);
    }
    //setThisDay(1);
  };

  const nextMonth = async () => {
    if (currentMonth === 11) {
      setCurrentYear((prevYear) => prevYear + 1);
      setCurrentMonth(0);
    } else {
      setCurrentMonth((prevMonth) => prevMonth + 1);
    }
    //setThisDay(1);
  };

  const switchToMonthView = () => {
    // Implement your logic here
    setViewMode('month');
  };

  const switchToWeekView = () => {
    // Implement your logic here
    setViewMode('week');
  };

  const switchToListView = () => {
    // Implement your logic here
    setViewMode('day');
  };

  const setCurrentDate = (d) => {
    setCurrentDay({
      day: moment(d).format('DD'),
      week: moment(d).format('ddd'),
      dated: moment(d).format('MMMM Do YYYY'),
    });
  };

  const [selectedBatch, setSelectedBatch] = useState({});

  const setCurrentBatch = (q, dated, f) => {
    const currentDated = f
      ? moment(dated, 'DD-MM-YYYY').format('MMMM Do YYYY')
      : moment(dated).format('MMMM Do YYYY');
    // Assuming selectedBatch is a state variable in React
    setSelectedBatch({ ...q, currentDated });
  };

  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });

  const togglePopup = (event) => {
    if (!popShow) {
      openPopover(event);
    } else {
      closePopover();
    }
    setPopShow(!popShow);
  };
  const [search, setSearch] = useState('');

  const [thisWeek, setThisWeek] = useState(1);
  const [thisDay, setThisDay] = useState(1);
  const [thisDayData, setThisDayData] = useState(null);

  useEffect(() => {
    if (viewMode === 'week') {
      onWeekChange(thisWeek);
    }
    if (viewMode === 'day') {
      onDayChange(thisDay);
      setCurrentDate(
        `${new Date(currentYear + '-' + (currentMonth + 1) + '-' + thisDay)}`
      );
    }
  }, [thisDay, thisWeek, formattedCalendarDates, viewMode]);

  const onWeekChange = async (thisWeek) => {
    const totalDays = formattedCalendarDates.length;
    const totalWeeks = totalDays / 7;
    if (thisWeek <= totalWeeks && thisWeek > 0) {
      var arr = [];
      for (var i = 0; i < 7; i++) {
        arr.push(formattedCalendarDates[(thisWeek - 1) * 7 + i]);
      }
      setCurrentWeek(arr);

      /*  debugger; */
    } else if (thisWeek < 1) {
      await prevMonth();
    } else {
      await nextMonth();
      setThisWeek(1);
    }
  };

  const onDayChange = async (thisDay) => {
    const fullMonth = [];
    formattedCalendarDates.map((item) => {
      if (item) {
        fullMonth.push(item);
      }
    });

    const totalDays = fullMonth.length;

    /* debugger; */

    if (thisDay <= totalDays && thisDay > 0) {
      const data = fullMonth[thisDay - 1];

      /* debugger; */
      setThisDayData(data);
    } else if (thisDay < 1) {
      await prevMonth();
      /* debugger; */
    } else {
      await nextMonth();
      setThisDay(1);
      /* debugger; */
    }
  };

  const getScheduledBatches = async (startDate, endDate) => {
    setLoading(true);
    var filter = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    };
    if (search) {
      filter.searchText = search;
    }
    const collection = 'batch';
    const queryParams = new URLSearchParams({
      db,
      collection,
      ...filter,
    });

    try {
      const response = await axios.get(
        `/batches/search?${queryParams.toString()}`,
        {
          baseURL: apiURL,
        }
      );

      var bt = response.data;

      const calendarDatesData = await getCalendarDates(
        currentYear,
        currentMonth
      );
      //setCalendarDates(calendarDatesData);

      const formattedDates = calendarDatesData.map((item) => {
        if (item) {
          return {
            date: item.date,
            isToday: isToday(item.date),
            isDifferentMonth: isDifferentMonth(item.date),
            schedule: item.schedule,
          };
        } else {
          return null;
        }
      });

      const c = formattedDates;
      for (var ind in c) {
        const itm = c[ind];
        if (itm) {
          itm.schedule = [];
          var preSch = [];
          for (var ind in bt) {
            if (
              moment(bt[ind].date).format('YYYY-MM-DD') ===
              moment(itm.date).format('YYYY-MM-DD')
            ) {
              preSch.push(bt[ind]);
            }
          }
          const batches = organizeBatches(preSch);

          itm.schedule = batches;
        }
      }

      setFormattedCalendarDates(formattedDates);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const startDate = moment(`${currentYear}-${currentMonth + 1}-1`).format(
      'YYYY-MM-DD'
    );
    const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');

    console.log(startDate, 'endDate', endDate);
    getScheduledBatches(startDate, endDate);
  }, [currentMonth, currentYear]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const isOpenPopover = Boolean(anchorEl);
  const popoverId = isOpenPopover ? 'simple-popover' : undefined;

  return (
    <div className="mb-20">
      <Header
        title="QC Calendar"
        description="You can check course schedules according to the calendar dates and timings."
      />
      <div className="grid grid-cols-12 gap-2 container">
        <div
          className={`col-span-2 items-start flex-col p-2   ${
            hideSide ? 'hidden' : 'sm:flex'
          }`}
        >
          <div className="flex justify-between items-center w-full mb-2">
            <h4 className="w-full mx-2 text-lg text-blue-500">
              {monthNames[currentMonth]} {currentYear}
            </h4>
            <div className="flex justify-between items-center">
              <svg
                onClick={prevMonth}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
              <svg
                onClick={nextMonth}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </div>

          <div className="grid grid-cols-7 grid-rows-1 w-full">
            {/* Weekday headers */}
            {weekDays.map((day, index) => (
              <div
                key={index}
                className="text-center font-semibold text-xs p-2 py-2 h-10 flex justify-center items-center"
              >
                {day}
              </div>
            ))}

            {/* Calendar dates */}

            {formattedCalendarDates.map((item, index) => (
              <div
                key={index}
                className={`text-center p-2 h-8 flex justify-center items-center flex-col ${
                  item &&
                  todayIs ==
                    moment(
                      `${item.date.getDate()}-${
                        item.date.getMonth() + 1
                      }-${item.date.getFullYear()}`,
                      'D-M-YYYY'
                    ).format('DD-MM-YYYY')
                    ? 'today'
                    : ''
                } ${
                  item &&
                  moment(
                    `${item.date.getDate()}-${
                      item.date.getMonth() + 1
                    }-${item.date.getFullYear()}`,
                    'D-M-YYYY'
                  ).format('MMMM Do YYYY') === currentDay.dated
                    ? 'selectedDay'
                    : ''
                }`}
                onClick={() => {
                  setCurrentDate(item.date);
                  setThisDay(moment(item.date).format('DD'));
                }}
              >
                <p className="text-gray-600 text-xs cursor-pointer">
                  {item ? item.date.getDate() : ''}
                </p>
              </div>
            ))}
          </div>

          <div className="flex mt-10 justify-start items-center text-blue-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 mx-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
              />
            </svg>
            {currentDay.dated}
          </div>

          {formattedCalendarDates.map((i, d) => (
            <div key={d} className="w-full">
              {i && i.date.getDate() == currentDay.day && (
                <div className="w-full">
                  {i && (
                    <div className="w-full">
                      <p
                        className="p-4"
                        style={{
                          display:
                            (!loading && !i.schedule) || i.schedule === ''
                              ? 'block'
                              : 'none',
                        }}
                      >
                        No Schedule found
                      </p>
                      {i.schedule.map((qt, qtIndex) => (
                        <div
                          key={qtIndex}
                          className="w-full border-b border-green-400 pb-2"
                        >
                          {qt.batches.map((n, nIndex) => (
                            <div key={nIndex} className="w-full">
                              <div className="flex mt-5 justify-between items-center w-full pr-2">
                                <Link
                                  to={`/batch-list/${n.course}/${currentDay.dated}`}
                                  className="flex justify-start font-bold"
                                  state={{ batches: n?.batches || [] }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6 mx-2"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122"
                                    />
                                  </svg>
                                  {n.course}
                                </Link>
                                <p className="text-xs font-bold">
                                  {n.batches.length} Session
                                  {console.log('n.batches.length', n)}
                                </p>
                              </div>
                              <Link
                                to={`/batch-list/${n.course}/${currentDay.dated}`}
                                state={{ batches: qt?.batches || [] }}
                                className="flex mt-2 justify-between items-center w-full pr-2"
                              >
                                <div className="flex justify-start mx-2 text-xs">
                                  {n.teacher}
                                </div>
                                <p className="text-xs flex text-gray-400 justify-center items-center">
                                  {n.time} {n.timeAt}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-4 h-4 ml-2"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                    />
                                  </svg>
                                </p>
                              </Link>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={` ${hideSide ? 'col-span-10' : 'col-span-9'}`}>
          <div className="flex flex-col sm:flex-row w-full mx-4 justify-between items-center">
            <div className="flex w-full sm:w-auto justify-start my-2 sm:my-0">
              <div className="sm:mx-4">
                <svg
                  onClick={() => setHideSide(!hideSide)}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 hidden sm:flex h-6 cursor-pointer mr-0 sm:mr-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </div>
              {viewMode === 'month' && (
                <div className="mx-4 w-full sm:w-auto">
                  <div className="text-xl font-semibold mr-10 flex w-full sm:w-auto sm:justify-start sm:items-start justify-between items-center">
                    <svg
                      onClick={prevMonth}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 mx-2 ml-0"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>
                    {monthNames[currentMonth]}{' '}
                    <span className="mr-1">
                      {viewMode === 'day' && `, ${currentDay.day}`}
                    </span>{' '}
                    {currentYear}
                    <svg
                      onClick={nextMonth}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 mx-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </div>
                </div>
              )}
              {viewMode === 'week' && (
                <div className="mx-4 w-full sm:w-auto">
                  <div className="text-xl font-semibold mr-10 flex w-full sm:w-auto sm:justify-start sm:items-start justify-between items-center">
                    <svg
                      onClick={() => setThisWeek((thisWeek) => thisWeek - 1)}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 mx-2 ml-0"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>
                    {monthNames[currentMonth]}{' '}
                    <span className="mr-1">
                      {viewMode === 'day' && `, ${currentDay.day}`}
                    </span>{' '}
                    {currentYear}
                    <svg
                      onClick={() => setThisWeek((thisWeek) => thisWeek + 1)}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 mx-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </div>
                </div>
              )}
              {viewMode === 'day' && (
                <div className="mx-4 w-full sm:w-auto">
                  <div className="text-xl font-semibold mr-10 flex w-full sm:w-auto sm:justify-start sm:items-start justify-between items-center">
                    <svg
                      onClick={() => setThisDay((thisDay) => thisDay - 1)}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 mx-2 ml-0"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>
                    {monthNames[currentMonth]}
                    <span className="mx-1">{thisDay}</span>
                    {currentYear}
                    <svg
                      onClick={() => setThisDay((thisDay) => thisDay + 1)}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 mx-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </div>
            <div className="border flex flex-row w-full sm:w-auto rounded-full p-2 my-2 sm:my-0">
              <button
                onClick={switchToMonthView}
                className={`px-4 py-2 mr-2 rounded-full w-full sm:w-[100px] ${
                  viewMode === 'month' ? 'bg-blue-500 text-white' : ''
                }`}
              >
                Month
              </button>
              <button
                onClick={switchToWeekView}
                className={`px-4 py-2 w-full sm:w-[100px] rounded-full ${
                  viewMode === 'week' ? 'bg-blue-500 text-white' : ''
                }`}
              >
                Week
              </button>
              <button
                onClick={switchToListView}
                className={`px-4 py-2 w-full sm:w-[100px] rounded-full ${
                  viewMode === 'day' ? 'bg-blue-500 text-white' : ''
                }`}
              >
                Day
              </button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();

                const startDate = moment(
                  `${currentYear}-${currentMonth + 1}-1`
                ).format('YYYY-MM-DD');
                const endDate = moment(startDate)
                  .endOf('month')
                  .format('YYYY-MM-DD');
                getScheduledBatches(startDate, endDate);
              }}
            >
              <div className="flex hidden sm:flex justify-end items-center border border-gray-300 rounded-full p-2 py-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                  />
                </svg>
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  placeholder="Search Course"
                  className="border-0 w-[200px] text-sm no-border "
                />
                <button type="submit">Search</button>
              </div>
            </form>
          </div>

          {/* Calendar */}
          <div className="flex gap-0">
            {/* Render calendar based on viewMode */}
            {viewMode === 'month' && formattedCalendarDates.length > 0 && (
              // Render month view
              <div className="w-full">
                {/* Your month view JSX code here */}
                <div className="w-full">
                  {/* Weekday headers */}
                  <div className="grid grid-cols-7 gap-0">
                    {weekDays.map((day, index) => (
                      <div
                        key={index}
                        className="text-center font-semibold border-gray-100 p-2 py-2 h-12 border-bottom bg-gray-50 flex justify-center items-center bg-white"
                      >
                        {day}
                      </div>
                    ))}
                  </div>
                  {/* Calendar dates */}
                  <div className="grid grid-cols-7 gap-0">
                    {console.log(
                      '==.formattedCalendarDates.==',
                      formattedCalendarDates
                    )}
                    {formattedCalendarDates.map((item, index) => (
                      <div
                        key={index}
                        className="text-center border border-gray-100 p-2 min-h-40 flex justify-between items-start flex-col bg-white"
                      >
                        <p className="text-gray-600">
                          {item ? item.date.getDate() : ''}
                        </p>
                        {item && (
                          <div className="schedule flex justify-start items-start flex-wrap">
                            {item.schedule &&
                              item.schedule.map((qt, qtIndex) => (
                                <div
                                  onClick={togglePopup}
                                  key={qtIndex}
                                  className="w-full flex flex-wrap"
                                >
                                  {qt.batches.map((bt, btIndex) => (
                                    <div
                                      key={btIndex}
                                      className="mx-1 my-1 cursor-pointer ml-0 flex justify-start items-center"
                                      onClick={() => {
                                        setCurrentBatch(bt, item.date);
                                      }}
                                    >
                                      <span
                                        style={{
                                          backgroundColor: getColor(bt.course),
                                          fontWeight: 'bold',
                                          fontSize: '16px',
                                        }}
                                        className="bg-gray-200 justify-start items-center flex rounded-full border text-xs p-1 border rounded px-2"
                                      >
                                        <span
                                          style={{
                                            backgroundColor: getColor(
                                              bt.course,
                                              1
                                            ),
                                            fontWeight: 'bold',
                                          }}
                                          className="rd mr-1"
                                        ></span>
                                        {bt.course}
                                      </span>
                                      {bt.batches.length > 0 && (
                                        <span
                                          className="bdg"
                                          style={{
                                            borderWidth: '1px',
                                            borderColor: 'black',
                                          }}
                                        >
                                          {bt.batches.length}
                                        </span>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {viewMode === 'week' && formattedCalendarDates.length > 0 && (
              // Render week view

              <div className="grid grid-cols-7 gap-0 w-full mx-8">
                {/* Weekday headers */}
                {weekDays.map((day) => (
                  <div
                    key={day}
                    className="text-center font-semibold p-2 py-2 h-12 border-gray-100 border-bottom bg-gray-50 flex justify-center items-center bg-white"
                  >
                    {day}
                  </div>
                ))}

                {/* Calendar dates */}
                {console.log('currentWeek', currentWeek)}
                {currentWeek.map((item, index) => (
                  <div
                    key={index}
                    className="text-center border border-gray-100 p-2 min-h-40 flex justify-between items-start flex-col bg-white"
                  >
                    <p className="text-gray-600">
                      {item ? item.date.getDate() : ''}
                    </p>
                    {item && (
                      <div className="schedule flex justify-start items-start flex-wrap">
                        {item.schedule &&
                          item.schedule.map((qt, qtIndex) => (
                            <div
                              onClick={togglePopup}
                              key={qtIndex}
                              className="w-full flex flex-wrap"
                            >
                              {qt.batches.map((bt, btIndex) => (
                                <div
                                  key={btIndex}
                                  className="mx-1 my-1 cursor-pointer ml-0 flex justify-start items-center"
                                  onClick={() => {
                                    setCurrentBatch(bt, item.date);
                                  }}
                                >
                                  <span
                                    style={{
                                      backgroundColor: getColor(bt.course),
                                      fontWeight: 'bold',
                                    }}
                                    className="bg-gray-200 justify-start items-center flex rounded-full border text-xs p-1 border rounded px-2"
                                  >
                                    <span
                                      style={{
                                        backgroundColor: getColor(bt.course, 1),
                                        fontWeight: 'bold',
                                      }}
                                      className="rd mr-1"
                                    ></span>
                                    {bt.course}
                                  </span>
                                  {bt.batches.length > 0 && (
                                    <span className="bdg">
                                      {' '}
                                      {bt.batches.length}
                                    </span>
                                  )}
                                </div>
                              ))}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {viewMode === 'day' && formattedCalendarDates.length > 0 && (
              // Render day view
              <div className="grid grid-cols-1 gap-0 w-full">
                {/* Weekday headers */}
                {weekDays.map((day) => (
                  <div key={day} className="w-full">
                    {day === currentDay.week && (
                      <div className="text-center font-semibold  p-2 py-2 h-24 border-bottom bg-gray-50 flex justify-center items-center bg-white">
                        {currentDay.week} - {currentDay.dated}
                      </div>
                    )}
                  </div>
                ))}

                {/* Calendar dates */}
                {thisDayData && (
                  <div className="w-full">
                    {thisDayData && thisDayData.date && (
                      <div className="text-center border b-gray-100 p-2 flex justify-start items-start flex-col bg-white">
                        <div className="schedule flex justify-start items-start flex-wrap w-full">
                          <p
                            className="p-4"
                            style={{
                              display:
                                (!loading && !thisDayData.schedule) ||
                                thisDayData.schedule === ''
                                  ? 'block'
                                  : 'none',
                            }}
                          >
                            No Schedule found
                          </p>
                          {thisDayData.schedule &&
                            thisDayData.schedule.map((qt, qtIndex) => (
                              <div key={qtIndex} className="p-4 w-full mb-4">
                                {qt.batches.map((n, nIndex) => (
                                  <div
                                    key={nIndex}
                                    className="ml-l mb-12"
                                    style={{ borderColor: getColor(n.course) }}
                                  >
                                    <div className="flex border-b border-gray-200 pb-4 justify-between items-center font-bold ">
                                      <Link
                                        to={`/batch-list/${n.course}/${currentDay.dated}`}
                                        state={{ batches: n?.batches || [] }}
                                        className="flex justify-start"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          stroke="currentColor"
                                          className="w-6 h-6 mx-2"
                                          style={{
                                            color: getColor(n.course, 1),
                                          }}
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122"
                                          />
                                        </svg>
                                        <h4
                                          style={{
                                            color: getColor(n.course, 1),
                                          }}
                                        >
                                          {n.course}
                                        </h4>
                                      </Link>
                                    </div>
                                    <div className="flex flex-col gap-3">
                                      <Grid container spacing={2}>
                                        {n.batches.map((m, mIndex) => (
                                          <Grid item md={6} xs={12}>
                                            <Link
                                              to={`/batch-list/${n.course}/${currentDay.dated}/${m._id}`}
                                              key={mIndex}
                                              className="w-full"
                                            >
                                              {mIndex < 5 && (
                                                <div className="flex gap-4">
                                                  <img
                                                    src={`${imageUriPath}${m.imagePath}`}
                                                    style={{
                                                      height: '200px',
                                                      width: '150px',
                                                    }}
                                                  />
                                                  <div className="flex-1 w-full flex flex-col border-gray-500 pb-3 mb-3">
                                                    <div className="flex mt-2 justify-start items-start pr-2 flex-col">
                                                      <div className="flex justify-start mx-2 text-md font-bold">
                                                        {m?.teacher?.name}
                                                      </div>
                                                      <div className="flex gap-2">
                                                        {m.batch_no && (
                                                          <p className="bgd flex justify-center items-center bg-blue-800 text-white rounded-full p-2 text-xs py-1">
                                                            BATCH NO{' '}
                                                            {m.batch_no}
                                                          </p>
                                                        )}
                                                        {m.date &&
                                                          moment(
                                                            m.initialDate
                                                          ).isAfter() &&
                                                          m.registrationLink && (
                                                            <a
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                window.open(
                                                                  m.registrationLink,
                                                                  '_blank'
                                                                );
                                                              }}
                                                              href={
                                                                m.registrationLink
                                                              }
                                                              target="_blank"
                                                              className="bgd flex justify-center items-center bg-green-500 text-white rounded-full p-2 text-xs py-1"
                                                            >
                                                              Enroll now
                                                            </a>
                                                          )}
                                                      </div>
                                                    </div>
                                                    <div className="flex mt-2 justify-start items-center pr-2">
                                                      <div className="flex justify-start mx-2 text-xs">
                                                        {m.language}
                                                      </div>
                                                      <div className="dots"></div>
                                                      <p className="xxs flex justify-center items-center rounded-md p-2 text-xxs py-1">
                                                        {m.location}
                                                      </p>
                                                    </div>
                                                    <div className="flex mt-2 justify-start items-center pr-2">
                                                      <div className="flex justify-start items-center mx-2 text-xs ">
                                                        {m.time} {m.timeAt}{' '}
                                                        <div className="dots mx-2"></div>{' '}
                                                        {m.hours} Hour{' '}
                                                        {m.minutes} Minutes
                                                      </div>
                                                    </div>
                                                    <div className="mx-2 mt-2 text-xs">
                                                      {m.initialDate &&
                                                      moment(
                                                        m.initialDate
                                                      ).isAfter() ? (
                                                        <div className="flex justify-start items-center  mt-2">
                                                          Start On
                                                          <div className="dots mx-2"></div>{' '}
                                                          {moment(
                                                            m.initialDate
                                                          ).format(
                                                            'Do MMM YYYY'
                                                          )}
                                                        </div>
                                                      ) : (
                                                        m.initialDate && (
                                                          <div className="flex justify-start items-center   mt-2">
                                                            Started On
                                                            <div className="dots mx-2"></div>{' '}
                                                            {moment(
                                                              m.initialDate
                                                            ).format(
                                                              'Do MMM YYYY'
                                                            )}
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                    {m.date &&
                                                      moment(
                                                        m.initialDate
                                                      ).isAfter() &&
                                                      m.registrationLink && (
                                                        <div className="mx-2 animate-updown text-green-700 font-bold text-start mt-4">
                                                          Upcoming Course
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                              )}
                                            </Link>
                                          </Grid>
                                        ))}
                                      </Grid>
                                      {n.batches.length > 5 && (
                                        <button className="bg-[#2271b1] text-white rounded-md p-2 w-full">
                                          {n.batches.length - 5} More Courses
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Popover
        id={popoverId}
        open={isOpenPopover}
        anchorEl={anchorEl}
        onClose={togglePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: '400px', minHeight: '400px' }}>
          {popShow && (
            <div
              className={`popup w-[400px]  absolute h-auto top-0 bottom-0 right-0 block`}
            >
              <div className="popup bg-white rounded-md bg-white p-4">
                <div className="flex pb-4 justify-between items-center font-bold">
                  <Link
                    to={`/batch-list/${selectedBatch.course}/${selectedBatch.currentDated}`}
                    state={{ batches: selectedBatch?.batches || [] }}
                    className="flex justify-start text-blue-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 mx-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122"
                      />
                    </svg>
                    <h4>{selectedBatch.course}</h4>
                  </Link>

                  <div>
                    <svg
                      onClick={togglePopup}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 cursor-pointer"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18 18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                </div>
                {selectedBatch?.batches[0]?.course?.imagePath && (
                  <img
                    src={`${imageUriPath}${selectedBatch?.batches[0]?.course?.imagePath}`}
                    style={{ height: '200px', width: '100%' }}
                  />
                )}
                <div className="w-full">
                  {selectedBatch.batches.map((b, index) => (
                    <div className="border-b border-b-6 border-black mb-2 hover:bg-gray-200">
                      <Link
                        to={`/batch-list/${selectedBatch.course}/${selectedBatch.currentDated}/${b._id}`}
                        key={index}
                        className="w-full pb-3 mb-3"
                      >
                        <>
                          {b.date &&
                            moment(b.initialDate).isAfter() &&
                            b.registrationLink && (
                              <div className="mx-2 animate-updown text-green-700 font-bold text-start mt-4">
                                Upcoming Course
                              </div>
                            )}
                          <div className="flex mt-2 justify-between items-center w-full pr-2">
                            <div className="flex justify-start mx-2 text-sm font-bold">
                              {b.teacher?.name}
                            </div>
                            <div className="flex gap-2">
                              {b.batch_no && (
                                <p className="bgd flex justify-center items-center bg-blue-800 text-white rounded-full p-2 text-xs py-1">
                                  BATCH NO {b.batch_no}
                                </p>
                              )}
                              {b.date &&
                                moment(b.initialDate).isAfter() &&
                                b.registrationLink && (
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      window.open(b.registrationLink, '_blank');
                                    }}
                                    href={b.registrationLink}
                                    target="_blank"
                                    className="bgd flex justify-center items-center bg-green-500 text-white rounded-full p-2 text-xs py-1"
                                  >
                                    Enroll now
                                  </a>
                                )}
                            </div>
                          </div>
                          <div className="flex mt-2 justify-between items-center w-full pr-2">
                            <div className="flex justify-start mx-2 text-xs">
                              {b.language}
                            </div>
                            <p className="xxs flex justify-center items-center rounded-md p-2 text-xxs py-1">
                              {b.location}
                            </p>
                          </div>
                          <div className="flex mt-2 justify-between items-center w-full pr-2">
                            <div className="flex justify-start mx-2 text-xs font-bold">
                              {b.time} {b.timeAt} . {b.hours} Hour {b.minutes}{' '}
                              Minutes
                            </div>
                            <div className="flex justify-start mx-2 text-xs font-bold">
                              {b.initialDate &&
                              moment(b.initialDate).isAfter() ? (
                                <div className="flex justify-start items-center    mt-2">
                                  Start On:
                                  {moment(b.initialDate).format('Do MMM YYYY')}
                                </div>
                              ) : (
                                b.initialDate && (
                                  <div className="flex justify-start items-center    mt-2">
                                    Started On:
                                    {moment(b.initialDate).format(
                                      'Do MMM YYYY'
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </>
                      </Link>
                    </div>
                  ))}
                </div>
                {selectedBatch.batches.length > 5 && (
                  <button className="bg-[#2271b1] text-white rounded-md p-2 w-full">
                    {selectedBatch.batches.length - 5} More Courses
                  </button>
                )}
              </div>
            </div>
          )}
        </Box>
      </Popover>
    </div>
  );
}

export default HomeComponentNew;
