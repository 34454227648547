import axios from 'axios';
import { settings } from './Settings';
import { Header } from './components/Header';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams, Link } from 'react-router-dom';
import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material';
import TextfieldNoborder from './components/TextfieldNoborder';

const apiURL = settings.baseUrl;
const imageUriPath = settings.imageUriPath;
const db = settings.MongodbAppName;

function UpcomingCourcesComponent() {
  const { course } = useParams();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({
    courseTitle: '',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(2, 'weeks').format('YYYY-MM-DD'),
  });
  const [sortedBatches, setSortedBatches] = useState([]);
  const [batch, setBatch] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    getCourses();
  }, []);

  const getCourses = async () => {
    setLoading(true);

    const collection = 'courses';
    const filter = { status: 'true' };
    const queryParams = new URLSearchParams({
      db,
      collection,
      filter: JSON.stringify(filter),
    });
    try {
      const response = await axios.get(`/read?${queryParams.toString()}`, {
        baseURL: apiURL,
      });
      setCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    var temBatches = {};
    batch.map((item) => {
      if (!temBatches[item?.course.title]) {
        temBatches[item?.course.title] = [];
      }
      temBatches[item?.course.title].push(item);
    });
    console.log(temBatches);

    setSortedBatches(sortObject(temBatches));
  }, [batch]);

  function sortObject(obj) {
    return Object.keys(obj)
      .sort()
      .reduce(function (result, key) {
        result[key] = obj[key];
        return result;
      }, {});
  }

  const getBatch = async (c) => {
    var dates = null;
    monthsArray.forEach((item, _index) => {
      if (item.month === search.repeat) {
        dates = item;
      }
    });

    console.log('dates =>', dates);

    setLoading(true);

    const collection = 'batch';
    var filter = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(52, 'weeks').format('YYYY-MM-DD'),
    };

    if (dates) {
      //filter.startDate = moment(dates.startDate).format('YYYY-MM-DD');
      //filter.endDate = moment(dates.endDate).format('YYYY-MM-DD');
      filter.startDate = dates.startDate;
      filter.endDate = dates.endDate;
    }
    if (search.courseTitle) filter.courseTitle = search.courseTitle;

    console.log('filter =>', filter);
    dates?.startDate &&
      console.log(
        'moment(dates.startDate) =>',
        moment(dates.startDate).format('YYYY-MM-DD')
      );
    dates?.endDate &&
      console.log(
        'moment(dates.endDate) =>',
        moment(dates.endDate).format('YYYY-MM-DD')
      );
    const queryParams = new URLSearchParams({
      db,
      collection,
      ...filter,
    });
    try {
      const response = await axios.get(
        `/batches/search/advance?${queryParams.toString()}`,
        {
          baseURL: apiURL,
        }
      );
      setBatch(response.data);
    } catch (error) {
      console.error('Error fetching courses: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBatch();
  }, [search]);

  // Handle search criteria change
  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  // Handle apply search button click

  // Handle reset search button click

  // Helper function to return day

  // Helper function to return rest

  // JSX markup for rendering the component

  const getColor = (c, d) => {
    var hex = c.color.replace('#', '');
    const opacity = d ? 1 : 0.2;
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  function getNext12MonthsIncludingCurrent() {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const today = new Date();
    const result = [];

    // Check if today is the last day of the month
    const isLastDayOfMonth =
      today.getDate() ===
      new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();

    if (!isLastDayOfMonth) {
      // If today is not the last day of the month, include the current month starting from tomorrow
      const currentMonth = new Date(today.getFullYear(), today.getMonth());
      const startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1
      ); // Starts from tomorrow
      //const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Last day of the current month
      const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD'); // Last day of the current month

      result.push({
        month: months[currentMonth.getMonth()],
        //startDate: startDate.toLocaleDateString(),
        //endDate: endDate.toLocaleDateString(),
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      });
    }

    // Generate the next 12 months if current month isn't included, otherwise 11 months
    const monthsToGenerate = isLastDayOfMonth ? 12 : 11;

    for (let i = 1; i <= monthsToGenerate; i++) {
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + i);
      const monthName = months[nextMonth.getMonth()];
      const startDate = new Date(
        nextMonth.getFullYear(),
        nextMonth.getMonth(),
        1
      ); // First day of the month
      /* const endDate = new Date(
        nextMonth.getFullYear(),
        nextMonth.getMonth() + 1,
        0
      ); // Last day of the month */
      const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');

      result.push({
        month: monthName,
        //startDate: startDate.toLocaleDateString(),
        //endDate: endDate.toLocaleDateString(),
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      });
    }

    return result;
  }

  const [monthsArray] = useState(getNext12MonthsIncludingCurrent() || []);

  useEffect(() => {
    if (monthsArray && Array.isArray(monthsArray) && monthsArray.length > 0) {
      setSearch((prevSearch) => ({
        ...prevSearch,
        repeat: monthsArray[0].month,
      }));
    }
  }, [monthsArray]);

  return (
    <div>
      <Header
        title="Upcoming Cources"
        description="You can check the courses that will start in the future."
      />
      <div>
        {/* Search and Filter UI */}
        <div className="container">
          <div>
            <div className="px-5 py-2 mt-1 flex flex-col gap-3.5">
              <div className="grid grid-cols-6 gap-3.5">
                <div className="sm:col-span-2 col-span-6 mt-2">
                  <label className="inline-block mb-2">Course</label>
                  <select
                    name="courseTitle"
                    value={search.courseTitle}
                    onChange={handleSearchChange}
                    className="bg-[length:20px_auto] disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 bg-chevron-black transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50  sm:mr-2"
                    aria-label="Select Course"
                  >
                    <option value="">All Courses</option>
                    {courses.map((course) => (
                      <option key={course._id} value={course.title}>
                        {course.title}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="sm:col-span-2 col-span-6 mt-2">
                  <label className="inline-block mb-2">Months</label>
                  <select
                    name="repeat"
                    value={search.repeat}
                    onChange={handleSearchChange}
                    className="bg-[length:20px_auto] disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50 [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 bg-chevron-black transition duration-200 ease-in-out w-full text-sm border-slate-300/60 shadow-sm rounded-md py-2 px-3 pr-8 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50  sm:mr-2"
                    aria-label="Select Teacher"
                  >
                    <option value={''}>Select</option>

                    {Array.isArray(monthsArray) &&
                      monthsArray.map((item, index) => (
                        <option
                          key={index}
                          value={item.month}
                          defaultValue={index === 0}
                        >
                          {item.month}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{/* Your search and filter UI here */}</div>

        {/* Batch Display */}

        <div>
          <div className="w-full">
            <div className="text-center border b-gray-100 p-2 flex justify-start items-start flex-col bg-white">
              {sortedBatches &&
                Object.keys(sortedBatches).map((courseKey, nIndex) => (
                  <div
                    key={nIndex}
                    className="ml-l mb-12 w-full"
                    style={{
                      borderColor: getColor(sortedBatches[courseKey][0].course),
                    }}
                  >
                    <div
                      style={{
                        color: getColor(sortedBatches[courseKey][0].course, 1),
                      }}
                      className="font-bold text-3xl text-start my-6 "
                    >
                      {courseKey}
                    </div>
                    <hr className="mb-2" />
                    <Grid container spacing={3}>
                      {sortedBatches[courseKey].map((batch, bIndex) => (
                        <Grid item lg={4} sm={6} xs={12} key={bIndex}>
                          <Link
                            to={`/batch-list/${batch.course?.title}/${batch.date}/${batch._id}`}
                            key={bIndex}
                            className="w-full"
                          >
                            <div className="flex gap-4">
                              <img
                                src={`${imageUriPath}${batch.imagePath}`}
                                style={{
                                  height: '200px',
                                  width: '150px',
                                }}
                              />
                              <div className="flex-1 w-full flex flex-col border-gray-500 pb-3 mb-3">
                                <div className="flex mt-2 justify-start items-start pr-2 flex-col">
                                  <div className="flex justify-start mx-2 text-lg font-bold">
                                    {batch?.teacher?.name}
                                  </div>
                                  <div className="flex gap-2">
                                    {batch.batch_no && (
                                      <p className="bgd flex justify-center items-center bg-blue-800 text-white rounded-full p-2 text-xs py-1">
                                        BATCH NO {batch.batch_no}
                                      </p>
                                    )}
                                    {batch.date &&
                                      moment(batch.date).isAfter() &&
                                      batch.registrationLink && (
                                        <a
                                          onClick={(e) => {
                                            e.preventDefault();
                                            window.open(
                                              batch.registrationLink,
                                              '_blank'
                                            );
                                          }}
                                          href={batch.registrationLink}
                                          target="_blank"
                                          className="bgd flex justify-center items-center bg-green-500 text-white rounded-full p-2 text-xs py-1"
                                        >
                                          Enroll now
                                        </a>
                                      )}
                                  </div>
                                </div>
                                <div className="flex mt-2 justify-start items-center pr-2">
                                  <div className="flex justify-start mx-2 text-sm">
                                    {batch.language}
                                  </div>
                                  <div className="dots"></div>
                                  <p className="xxs flex justify-center items-center rounded-md p-2 text-sm py-1">
                                    {batch.location}
                                  </p>
                                </div>
                                <div className="flex mt-2 justify-start items-center pr-2">
                                  <div className="flex justify-start items-center mx-2 text-sm ">
                                    {batch.time} {batch.timeAt}{' '}
                                    <div className="dots mx-2"></div>{' '}
                                    {batch.hours} Hour {batch.minutes} Minutes
                                  </div>
                                </div>

                                <div className="mx-2">
                                  {batch.date &&
                                  moment(batch.date).isAfter() ? (
                                    <div className="flex justify-start items-center text-sm  mt-2">
                                      Start On
                                      <div className="dots mx-2"></div>{' '}
                                      {moment(batch.date).format('Do MMM YYYY')}
                                    </div>
                                  ) : (
                                    batch.date && (
                                      <div className="flex justify-start items-center text-sm  mt-2">
                                        Started On
                                        <div className="dots mx-2"></div>{' '}
                                        {moment(batch.date).format(
                                          'Do MMM YYYY'
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                                {batch.date &&
                                  moment(batch.date).isAfter() &&
                                  batch.registrationLink && (
                                    <div className="mx-2">
                                      <div className="animate-updown text-green-700 font-bold text-start mt-4">
                                        Upcoming Course
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default UpcomingCourcesComponent;
